// src/localData.ts
// import { v4 as uuidv4 } from 'uuid';
import { Player, TestDefinition } from './types/interfaces';
import { Combine } from './types/interfaces';




export const combines: Combine[] = [
  { id: 'C001', name: 'Summer Combine 2024', date: '2024-07-15', status: 'upcoming' },
  { id: 'C002', name: 'Winter Combine 2024', date: '2024-12-10', status: 'upcoming' },
];

export const availableTests: TestDefinition[] = [
  {
    id: 'T001',
    name: 'Slapshot Speed',
    shortName: 'SlpSht',
    description: 'Measure the speed of the player\'s slapshot using a radar gun.',
    units: 'mph',
    precision: 1,
    type: 'On-Ice',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 120,
    defaultAttempts: 3
  },
  {
    id: 'T002',
    name: 'Skating Agility',
    shortName: 'SktAgl',
    description: 'Measure the time taken to complete a specified skating course.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T003',
    name: 'Vertical Jump',
    shortName: 'VrtJmp',
    description: 'Measure the player\'s vertical jump height.',
    units: 'inches',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 48,
    defaultAttempts: 3
  },
  {
    id: 'T004',
    name: 'Weight',
    shortName: 'Wght',
    description: 'Measure the player\'s body weight.',
    units: 'lbs',
    precision: 1,
    type: 'Gym',
    scoreDirection: 'Neutral',
    dataType: 'number',
    minValue: 50,
    maxValue: 300,
    defaultAttempts: 1
  },
  {
    id: 'T005',
    name: '10 Yard Sprint',
    shortName: '10Yrd',
    description: 'Measure the time taken to sprint 10 yards.',
    units: 'seconds',
    precision: 2,
    type: 'Gym',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 10,
    defaultAttempts: 2
  },
  {
    id: 'T006',
    name: 'Pro Agility',
    shortName: 'PrAgl',
    description: 'Measure the time taken to complete the pro agility shuttle run.',
    units: 'seconds',
    precision: 2,
    type: 'Gym',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T007',
    name: 'Chin-ups',
    shortName: 'ChnUp',
    description: 'Count the number of chin-ups completed.',
    units: 'reps',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 50,
    defaultAttempts: 1
  },
  {
    id: 'T008',
    name: 'Push-ups',
    shortName: 'PshUp',
    description: 'Count the number of push-ups completed in one minute.',
    units: 'reps',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 100,
    defaultAttempts: 1
  },
  {
    id: 'T009',
    name: 'Goal to Blue Line',
    shortName: 'GlBln',
    description: 'Measure the time taken to skate from the goal line to the blue line.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T010',
    name: 'Lap Skating',
    shortName: 'LpSkt',
    description: 'Measure the time taken to skate one full lap around the rink.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
];

export const players: Player[] = [
  { 
    id: 'P001', 
    firstName: 'John',
    lastName: 'Doe',
    dateOfBirth: '1990-01-01',
    picture: 'https://example.com/john.jpg',
    position: 'Forward',
    team: 'Team A'
  },
  { 
    id: 'P002', 
    firstName: 'Jane',
    lastName: 'Smith',
    dateOfBirth: '1992-05-15',
    picture: 'https://example.com/jane.jpg',
    position: 'Defense',
    team: 'Team B'
  },
  { 
    id: 'P003', 
    firstName: 'Mike',
    lastName: 'Johnson',
    dateOfBirth: '1988-11-30',
    picture: 'https://example.com/mike.jpg',
    position: 'Goalie',
    team: 'Team C'
  },
];
  // export const tests = [
  //   { id: 'T001', name: 'Slapshot Speed' },
  //   { id: 'T002', name: 'Skating Agility' },
  //   { id: 'T003', name: 'Vertical Jump' },
  // ];


// ... (previous combines and players data)

export const tests: TestDefinition[] = [
  {
    id: 'T001',
    name: 'Slapshot Speed',
    shortName: 'SlpSht',
    description: 'Measure the speed of the player\'s slapshot using a radar gun.',
    units: 'mph',
    precision: 1,
    type: 'On-Ice',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 120,
    defaultAttempts: 3
  },
  {
    id: 'T002',
    name: 'Skating Agility',
    shortName: 'SktAgl',
    description: 'Measure the time taken to complete a specified skating course.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T003',
    name: 'Vertical Jump',
    shortName: 'VrtJmp',
    description: 'Measure the player\'s vertical jump height.',
    units: 'inches',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 48,
    defaultAttempts: 3
  },
  {
    id: 'T004',
    name: 'Weight',
    shortName: 'Wght',
    description: 'Measure the player\'s body weight.',
    units: 'lbs',
    precision: 1,
    type: 'Gym',
    scoreDirection: 'Neutral',
    dataType: 'number',
    minValue: 50,
    maxValue: 300,
    defaultAttempts: 1
  },
  {
    id: 'T005',
    name: '10 Yard Sprint',
    shortName: '10Yrd',
    description: 'Measure the time taken to sprint 10 yards.',
    units: 'seconds',
    precision: 2,
    type: 'Gym',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T006',
    name: 'Pro Agility',
    shortName: 'PrAgl',
    description: 'Measure the time taken to complete the pro agility shuttle run.',
    units: 'seconds',
    precision: 2,
    type: 'Gym',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T007',
    name: 'Chin-ups',
    shortName: 'ChnUp',
    description: 'Count the number of chin-ups completed.',
    units: 'reps',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 50,
    defaultAttempts: 1
  },
  {
    id: 'T008',
    name: 'Push-ups',
    shortName: 'PshUp',
    description: 'Count the number of push-ups completed in one minute.',
    units: 'reps',
    precision: 0,
    type: 'Gym',
    scoreDirection: 'Ascending',
    dataType: 'number',
    minValue: 0,
    maxValue: 100,
    defaultAttempts: 1
  },
  {
    id: 'T009',
    name: 'Goal to Blue Line',
    shortName: 'GlBln',
    description: 'Measure the time taken to skate from the goal line to the blue line.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
  {
    id: 'T010',
    name: 'Lap Skating',
    shortName: 'LpSkt',
    description: 'Measure the time taken to skate one full lap around the rink.',
    units: 'seconds',
    precision: 2,
    type: 'On-Ice',
    scoreDirection: 'Descending',
    dataType: 'number',
    minValue: 0,
    maxValue: 99.99,
    defaultAttempts: 2
  },
];