import React from 'react';
import { Sheet, Typography } from '@mui/joy';
import { Player } from '../types/interfaces';

interface PlayerDetailsProps {
  player: Player;
}

const PlayerDetails: React.FC<PlayerDetailsProps> = ({ player }) => {
  return (
    <Sheet>
      <Typography level="h2">Player Details</Typography>
      <Typography>First Name: {player.firstName}</Typography>
      <Typography>Last Name: {player.lastName}</Typography>
      <Typography>Date of Birth: {new Date(player.dateOfBirth).toLocaleDateString()}</Typography>
      <Typography>Position: {player.position || 'N/A'}</Typography>
      <Typography>Team: {player.team || 'N/A'}</Typography>
    </Sheet>
  );
};

export default PlayerDetails;