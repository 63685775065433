import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sheet, Typography, Button, Modal, ModalDialog, ModalClose, Input, FormControl, FormLabel, CircularProgress, List, Box, Divider } from '@mui/joy';
import { getAllCombines, createCombine } from '../api';
import { Combine } from '../types/interfaces';

const CombinesList: React.FC = () => {
  const [combines, setCombines] = useState<Combine[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [newCombine, setNewCombine] = useState<Partial<Combine>>({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchCombines();
  }, []);

  const fetchCombines = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllCombines();
      setCombines(response.data);
    } catch (err) {
      console.error('Error fetching combines:', err);
      setError('Failed to fetch combines. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCombine = async () => {
    if (!newCombine.name || !newCombine.date) {
      setError('Please fill in all required fields.');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    try {
      const response = await createCombine({
        ...newCombine,
        status: 'upcoming'
      });
      setCombines(prevCombines => [...prevCombines, response.data]);
      setNewCombine({});
      setOpenModal(false);
      navigate(`/combines/${response.data.id}`);
    } catch (err) {
      console.error('Error creating combine:', err);
      setError('Failed to create combine. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImportCombine = () => {
    navigate('/import-combine');
  };

  if (isLoading) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>Combines</Typography>
      {error && (
        <Typography color="danger" sx={{ mb: 2 }}>{error}</Typography>
      )}
      <Sheet sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Button onClick={() => setOpenModal(true)}>Create New Combine</Button>
        <Button onClick={handleImportCombine}>Import Combine</Button>
      </Sheet>
      <List>
        {combines.map((combine) => (
          <Button 
            key={combine.id} 
            variant="outlined" 
            sx={{ 
              mb: 2, 
              p: 2, 
              display: 'flex', 
              textAlign: 'left', 
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              height: 'auto',
            }}
            onClick={() => navigate(`/combines/${combine.id}`)}
          >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography level="h2" sx={{ fontSize: 'xl' }}>{combine.name}</Typography>
              <Typography>Date: {combine.date}</Typography>
              {combine.location && <Typography>Location: {combine.location}</Typography>}
              <Typography 
                level="body-sm"
                sx={{ 
                  mt: 1, 
                  fontWeight: 'bold',
                  color: combine.status === 'upcoming' ? 'primary.500' : 
                         combine.status === 'ongoing' ? 'warning.500' : 'success.500'
                }}
              >
                Status: {combine.status.charAt(0).toUpperCase() + combine.status.slice(1)}
              </Typography>
            </Box>
            {combine.organizationLogoUris && combine.organizationLogoUris.length > 0 && (
              <>
                <Divider orientation="vertical" sx={{ mx: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: '100px' }}>
                  {combine.organizationLogoUris.map((uri, index) => (
                    <img 
                      key={index} 
                      src={uri} 
                      alt={`Organization logo ${index + 1}`} 
                      style={{ maxWidth: '60px', maxHeight: '45px', objectFit: 'contain' }} 
                    />
                  ))}
                </Box>
              </>
            )}
          </Button>
        ))}
      </List>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h2">Create New Combine</Typography>
          <form onSubmit={(e) => { e.preventDefault(); handleCreateCombine(); }}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input 
                value={newCombine.name || ''} 
                onChange={(e) => setNewCombine({...newCombine, name: e.target.value})}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input 
                type="date"
                value={newCombine.date || ''} 
                onChange={(e) => setNewCombine({...newCombine, date: e.target.value})}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Location (optional)</FormLabel>
              <Input 
                value={newCombine.location || ''} 
                onChange={(e) => setNewCombine({...newCombine, location: e.target.value})}
              />
            </FormControl>
            <Button type="submit" sx={{ mt: 2 }}>Create Combine</Button>
          </form>
        </ModalDialog>
      </Modal>
    </Sheet>
  );
};

export default CombinesList;