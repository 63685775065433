// src/types/interfaces.ts

export interface Combine {
    id: string;
    name: string;
    date: string;
    location?: string;
    status: 'upcoming' | 'ongoing' | 'completed';
    testIds?: string[]; // Add this line
    usesSessions?: boolean;
    organizationLogoUris?: string[];
  }
  
export interface CombineSession {
  id: string;
  combineId: string;
  name: string;
  date: string;
  status: 'not_started' | 'in_progress' | 'completed';
  roster: CombineSessionRoster[];
  testSchedule: TestScheduleItem[];
}

export interface CombineData {
  combine: Combine;
  players: Player[];
  availableTests: TestDefinition[];
  selectedTests: string[];
  results: TestResult[];
  sessions: CombineSession[];
}

export interface TestScheduleItem {
  testId: string;
  attempts: number;
  order: number;
}

export interface Player {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  picture?: string;
  position?: string;
  team?: string;
  nickname?: string;  // Added
  notes?: string;     // Added
}

export interface CombineSessionRoster {
  playerId: string;
  isPresent: boolean;
}

export interface TestDefinition {
  id: string;
  name: string;
  shortName: string;
  description: string;
  units: string;
  precision: number;
  type: 'Gym' | 'On-Ice' | 'Other';
  scoreDirection: 'Ascending' | 'Descending' | 'Neutral';
  dataType: 'number' | 'string' | 'boolean';
  minValue?: number;
  maxValue?: number;
  defaultAttempts: number;
}
  
export interface TestResult {
  id: string;
  combineId: string;
  sessionId?: string;
  playerId: string;
  testId: string;
  value: number;
  timestamp: string;
  attemptNumber?: number;
}

export interface AdvancedResult extends TestResult {
  rank: number;
  zScore: number;
}

export interface User {
  id: string;
  username?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  language: string;
  isFirstTimeVisitor?: boolean;
  lastEULASigned?: string;
}

export enum UnitType {
  Reps = 'reps',
  Seconds = 'seconds',
  Inches = 'inches',
  Lbs = 'lbs',
  Mph = 'mph'
}

export enum TestType {
  Gym = 'Gym',
  OnIce = 'On-Ice',
  Other = 'Other'
}

export enum ScoreDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
  Neutral = 'Neutral'
}

export type DataType = 'number' | 'string' | 'boolean';

export interface Organization {
  id: string;
  name: string;
  longName?: string;
  logoUri?: string;
  description?: string;
  websiteUrl?: string;
  primaryColor?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
}

export interface Feedback {
  id?: string;
  type?: string;
  text: string;
  url?: string;
  timestamp?: string;
  userId?: string;
  details?: Record<string, any>;
}

export interface UserAgreementLog {
  userId: string;
  version: string;
  agreedAt: string;
  userAgent: string;
}

export interface Connection {
  id: string;
  entity1Id: string;
  entity1Type: 'user' | 'player' | 'organization';
  entity2Id: string;
  entity2Type: 'user' | 'player' | 'organization';
  relationshipType: string;
  entityDetails: {
    id: string;
    type: 'user' | 'player' | 'organization';
    name: string;
    avatar?: string;
  };
}