// TestSelection.tsx
import React, { useState, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Sheet,
  Box,
  Chip,
  Modal,
  ModalDialog,
  ModalClose,
  Tooltip,
  IconButton
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import { tests } from '../localData';
import { TestDefinition } from '../types/interfaces';

const TestSelection: React.FC = () => {
  const { combineId } = useParams<{ combineId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [modalTest, setModalTest] = useState<TestDefinition | null>(null);

  // Get selected players from URL
  const searchParams = new URLSearchParams(location.search);
  const selectedPlayers = searchParams.get('players')?.split(',') || [];

  const { onIceTests, gymTests } = useMemo(() => {
    return tests.reduce((acc, test) => {
      if (test.type === 'On-Ice') acc.onIceTests.push(test);
      else if (test.type === 'Gym') acc.gymTests.push(test);
      return acc;
    }, { onIceTests: [] as TestDefinition[], gymTests: [] as TestDefinition[] });
  }, []);

  const handleTestToggle = (testId: string, event?: React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
    // Prevent double-toggling when checkbox is clicked directly
    if (event && (event.target as HTMLElement).closest('.MuiCheckbox-root')) {
      return;
    }
    setSelectedTests(prev =>
      prev.includes(testId) ? prev.filter(id => id !== testId) : [...prev, testId]
    );
  };

  const handleStartSession = () => {
    const publicSessionId = Math.random().toString(36).substr(2, 9);
    navigate(`/combines/${combineId}/sessions/${publicSessionId}/measure?tests=${selectedTests.join(',')}&players=${selectedPlayers.join(',')}`);
  };

  const renderTestList = (testList: TestDefinition[], title: string) => (
    <Box sx={{ flex: 1, minWidth: 300, mb: { xs: 4, md: 0 } }}>
      <Typography level="h2" sx={{ fontSize: 'xl', fontWeight: 'bold', mb: 2 }}>
        {title}
      </Typography>
      <List>
        {testList.map((test) => (
          <ListItem key={test.id}>
            <ListItemButton
              onClick={() => handleTestToggle(test.id)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 1,
                px: 2,
              }}
            >
              <Checkbox
                checked={selectedTests.includes(test.id)}
                onChange={(event) => handleTestToggle(test.id, event)}
                sx={{ mr: 2 }}
              />
              <Typography level="body-md" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                {test.name}
              </Typography>
              <Tooltip title="View test details" arrow>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalTest(test);
                  }}
                  size="sm"
                  sx={{
                    ml: 2,
                    '&:hover': { backgroundColor: 'action.hover' },
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Sheet variant="outlined" sx={{ p: 4, maxWidth: 1200, mx: 'auto' }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 2 }}>Select Tests for Session</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Chip color="primary" size="lg">
          {selectedPlayers.length} players selected
        </Chip>
        <Chip color="success" size="lg" sx={{ ml: 2 }}>
          {selectedTests.length} tests selected
        </Chip>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 4,
        mb: 4
      }}>
        {renderTestList(onIceTests, "On-Ice Tests")}
        {renderTestList(gymTests, "Gym Tests")}
      </Box>

      <Button
        onClick={handleStartSession}
        color="primary"
        variant="solid"
        size="lg"
        fullWidth
        sx={{ mt: 4 }}
        disabled={selectedTests.length === 0}
      >
        Start Session
      </Button>

      <Modal open={!!modalTest} onClose={() => setModalTest(null)}>
        <ModalDialog>
          <ModalClose />
          {modalTest && (
            <>
              <Typography level="h2">{modalTest.name}</Typography>
              <Typography level="body-md" sx={{ mt: 2, mb: 1 }}>Description:</Typography>
              <Typography level="body-sm">{modalTest.description}</Typography>
              <Typography level="body-md" sx={{ mt: 2, mb: 1 }}>Details:</Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                <li>Type: {modalTest.type}</li>
                <li>Units: {modalTest.units}</li>
                <li>Precision: {modalTest.precision}</li>
                <li>Score Direction: {modalTest.scoreDirection}</li>
                <li>Default Attempts: {modalTest.defaultAttempts}</li>
              </Box>
            </>
          )}
        </ModalDialog>
      </Modal>
    </Sheet>
  );
};

export default TestSelection;