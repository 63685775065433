// src/components/ImportCombineModal.tsx

import React, { useState } from 'react';
import { Box, Button, Modal, Typography, ModalDialog, ModalClose } from '@mui/joy';
import Papa from 'papaparse';
import Dropzone from './Dropzone';
import { Player, TestDefinition, TestResult } from '../types/interfaces';

interface ImportCombineModalProps {
  open: boolean;
  onClose: () => void;
  onImportComplete: (players: Player[], testResults: TestResult[]) => void;
  existingPlayers: Player[];
  testDefinitions: TestDefinition[];
}

interface CSVRow {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  position?: string;
  team?: string;
  [key: string]: string | undefined;
}

const ImportCombineModal: React.FC<ImportCombineModalProps> = ({
  open,
  onClose,
  onImportComplete,
  existingPlayers,
  testDefinitions,
}) => {
  const [step, setStep] = useState(1);
  const [csvData, setCsvData] = useState<CSVRow[]>([]);
  const [playerMatches, setPlayerMatches] = useState<{ [key: string]: Player | null }>({});
  const [newPlayers, setNewPlayers] = useState<Partial<Player>[]>([]);
  const [testResults, setTestResults] = useState<Partial<TestResult>[]>([]);

  console.log(csvData)
  const handleFileAccepted = (file: File) => {
    Papa.parse<CSVRow>(file, {
      complete: (results) => {
        setCsvData(results.data);
        processCSVData(results.data);
        setStep(2);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const processCSVData = (data: CSVRow[]) => {
    const matches: { [key: string]: Player | null } = {};
    const newPlayersList: Partial<Player>[] = [];
    const testResultsList: Partial<TestResult>[] = [];

    data.forEach((row) => {
      const { firstName, lastName, dateOfBirth, position, team, ...testData } = row;
      const key = `${firstName}-${lastName}-${dateOfBirth}`;
      
      const existingPlayer = existingPlayers.find(
        (p) => p.firstName === firstName && p.lastName === lastName && p.dateOfBirth === dateOfBirth
      );

      if (existingPlayer) {
        matches[key] = existingPlayer;
      } else {
        matches[key] = null;
        newPlayersList.push({ firstName, lastName, dateOfBirth, position, team });
      }

      // Process test results
      Object.entries(testData).forEach(([testName, value]) => {
        const testDefinition = testDefinitions.find((td) => td.name === testName);
        if (testDefinition && value) {
          testResultsList.push({
            testId: testDefinition.id,
            value: parseFloat(value),
          });
        }
      });
    });

    setPlayerMatches(matches);
    setNewPlayers(newPlayersList);
    setTestResults(testResultsList);
  };

  const handleImport = () => {
    // Here you would typically make API calls to create new players,
    // associate players with the combine, and create test results.
    // For now, we'll just call onImportComplete with the processed data.
    const playersToImport = [
      ...Object.values(playerMatches).filter((p): p is Player => p !== null),
      ...newPlayers as Player[],
    ];
    onImportComplete(playersToImport, testResults as TestResult[]);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ minWidth: 400 }}>
        <ModalClose />
        <Typography level="h2">Import Combine Data</Typography>
        {step === 1 && (
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 2 }}>Upload a CSV file with player and test result data</Typography>
            <Dropzone onFileAccepted={handleFileAccepted} />
          </Box>
        )}
        {step === 2 && (
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mb: 2 }}>Review imported data</Typography>
            <Typography>New players to be created: {newPlayers.length}</Typography>
            <Typography>Existing players matched: {Object.values(playerMatches).filter(p => p !== null).length}</Typography>
            <Typography>Test results to be imported: {testResults.length}</Typography>
            <Button onClick={handleImport} sx={{ mt: 2 }}>Confirm Import</Button>
          </Box>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default ImportCombineModal;