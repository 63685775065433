import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Grid, List, ListItem, ListItemButton, CircularProgress, Avatar } from '@mui/joy';
import { getCurrentUser, getAllCombines, getAllPlayers, getAllTestDefinitions, getCurrentUserConnections } from '../api';
import { User, Connection } from '../types/interfaces';

const translations = {
  welcome: {
    en: 'Welcome',
    fr: 'Bienvenue',
    sv: 'Välkommen',
    uk: 'Ласкаво просимо'
  },
  firstTimeVisitor: {
    en: 'Welcome to the Combine Management System. Here you can manage your combines, players, and tests. Let\'s get started!',
    fr: 'Bienvenue dans le système de gestion des combines. Ici, vous pouvez gérer vos combines, vos joueurs et vos tests. Commençons !',
    sv: 'Välkommen till Combine Management System. Här kan du hantera dina combines, spelare och tester. Låt oss börja!',
    uk: 'Ласкаво просимо до системи управління Combine. Тут ви можете керувати своїми combines, гравцями та тестами. Давайте почнемо!'
  },
  whatToDo: {
    en: 'What would you like to do today?',
    fr: 'Que voulez-vous faire aujourd\'hui ?',
    sv: 'Vad vill du göra idag?',
    uk: 'Що б ви хотіли зробити сьогодні?'
  }
};

type TranslationKey = keyof typeof translations;
type LanguageCode = 'en' | 'fr' | 'sv' | 'uk';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<User | null>(null);
  const [stats, setStats] = useState({ combines: 0, players: 0, tests: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [connections, setConnections] = useState<Connection[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          userResponse, 
          combinesResponse, 
          playersResponse, 
          testsResponse,
          connectionsResponse
        ] = await Promise.all([
          getCurrentUser(),
          getAllCombines(),
          getAllPlayers(),
          getAllTestDefinitions(),
          getCurrentUserConnections()
        ]);

        setUserData(userResponse.data);
        setStats({
          combines: combinesResponse.data.length,
          players: playersResponse.data.length,
          tests: testsResponse.data.length,
        });
        setConnections(connectionsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const getTranslation = (key: TranslationKey): string => {
    const lang = (userData?.language as LanguageCode) || 'en';
    return translations[key][lang] || translations[key]['en'];
  };

  const options = [
    { text: 'Start a New Combine', path: '/combines' },
    { text: 'View Existing Combine Results', path: '/combines' },
    { text: 'Manage Players', path: '/players' },
    { text: 'Manage Tests', path: '/tests' },
    { text: 'Import a Combine', path: '/import-combine' },
  ];

  const renderConnectionCard = (connection: Connection) => {
    const handleClick = () => {
      switch (connection.entity2Type) {
        case 'player':
          navigate(`/players/${connection.entity2Id}`);
          break;
        case 'user':
          navigate(`/users/${connection.entity2Id}`);
          break;
        case 'organization':
          navigate(`/organizations/${connection.entity2Id}`);
          break;
      }
    };
  
    const isOrganization = connection.entity2Type === 'organization';
  
    return (
      <Grid xs={6} sm={4} md={3} key={connection.id}>
        <Card 
          variant="outlined"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 'md',
              borderColor: 'primary.500',
            },
            backgroundColor: 'background.level2',
          }}
          onClick={handleClick}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isOrganization ? (
              <img 
                src={connection.entityDetails.avatar} 
                alt={connection.entityDetails.name}
                style={{ width: 64, height: 64, objectFit: 'contain', marginBottom: '8px' }}
              />
            ) : (
              <Avatar 
                src={connection.entityDetails.avatar} 
                sx={{ width: 64, height: 64, mb: 1 }}
              />
            )}
            <Typography level="title-md">
              {connection.entityDetails.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  

  if (isLoading) {
    return (
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: 'background.surface',
      }}
    >
      <CardContent>
        <Typography level="h2" gutterBottom>
          {getTranslation('welcome')}
          {userData?.firstName && <>{', '}{userData.firstName}</>}!
        </Typography>

        {userData?.isFirstTimeVisitor && (
          <Typography level="body-lg" sx={{ mb: 2 }}>
            {getTranslation('firstTimeVisitor')}
          </Typography>
        )}

        <Typography level="h3" gutterBottom>
          {getTranslation('whatToDo')}
        </Typography>

        <List>
          {options.map((option, index) => (
            <ListItem key={index}>
              <ListItemButton onClick={() => navigate(option.path)}>
                <Typography>{option.text}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {connections.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography level="h3" sx={{ mb: 2 }}>
              Family
            </Typography>
            <Grid container spacing={2}>
              {connections.map(renderConnectionCard)}
            </Grid>
          </Box>
        )}

        <Typography level="h3" sx={{ mt: 4, mb: 2 }}>
          Quick Stats
        </Typography>

        <Grid container spacing={2}>
          {Object.entries(stats).map(([key, value]) => (
            <Grid xs={4} key={key}>
              <Card
                variant="outlined"
                sx={{ backgroundColor: 'background.level1' }}
              >
                <CardContent>
                  <Typography level="h4">{value}</Typography>
                  <Typography>{`${key.charAt(0).toUpperCase() + key.slice(1)}`}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: 'background.level1',
              '&:hover': {
                backgroundColor: 'background.level2',
              },
            }}
          >
            <CardContent>
              <Typography 
                level="title-lg" 
                sx={{ cursor: 'pointer' }} 
                onClick={() => navigate('/settings')}
              >
                Go to Settings
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Dashboard;