// SessionResults.tsx

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Sheet,
  Typography,
  Table,
  Button,
  Box,
} from '@mui/joy';
import type { TestDefinition, Player, TestResult } from '../types/interfaces';

const SessionResults: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { TestResults, selectedTests, selectedPlayers } = location.state as { 
    TestResults: TestResult[], 
    selectedTests: TestDefinition[], 
    selectedPlayers: Player[] 
  };

  const getPlayerResult = (playerId: string, testId: string) => {
    const result = TestResults.find(m => m.playerId === playerId && m.testId === testId);
    const test = selectedTests.find(t => t.id === testId);
    if (result && result.value !== null && result.value !== undefined) {
      return `${result.value} ${test?.units || ''}`;
    }
    return 'N/A';
  };

  const handleBackToMeasurement = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('tests', selectedTests.map(test => test.id).join(','));
    searchParams.set('players', selectedPlayers.map(player => player.id).join(','));
    
    navigate(`/combines/${TestResults[0]?.sessionId}/sessions/${TestResults[0]?.sessionId}/measure?${searchParams.toString()}`, {
      state: { TestResults, selectedTests, selectedPlayers }
    });
  };

  if (!TestResults || !selectedTests || !selectedPlayers) {
    return (
      <Sheet variant="outlined" sx={{ p: 4 }}>
        <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>
          Error: Session data not found
        </Typography>
        <Button color="primary" onClick={() => navigate('/')}>
          Return Home
        </Button>
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>
        Session Results
      </Typography>
      <Table>
        <thead>
          <tr>
            <th>Player</th>
            {selectedTests.map(test => (
              <th key={test.id}>{test.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {selectedPlayers.map(player => (
            <tr key={player.id}>
              <td>{player.firstName} {player.lastName}</td>
              {selectedTests.map(test => (
                <td key={test.id}>{getPlayerResult(player.id, test.id)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button color="neutral" onClick={handleBackToMeasurement}>
          Back to Measurement
        </Button>
        <Button color="primary" onClick={() => navigate('/')}>
          Finish and Return Home
        </Button>
      </Box>
    </Sheet>
  );
};

export default SessionResults;