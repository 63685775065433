import React, { useState, useEffect } from 'react';
import { Sheet, Typography, Checkbox, List, ListItem, ListItemButton, Button, Alert } from '@mui/joy';
import { useCombineDetails } from '../contexts/CombineDetailsContext';

const CombineTestSchedule: React.FC = () => {
  const { 
    combine, 
    allTests, 
    selectedTests, 
    updateTestSchedule, 
    isEditingAllowed,
    error
  } = useCombineDetails();

  const [localSelectedTests, setLocalSelectedTests] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    setLocalSelectedTests(selectedTests.map(test => test.id));
  }, [selectedTests]);

  const handleTestToggle = (testId: string) => {
    if (!isEditingAllowed(combine?.status || '')) return;
    setLocalSelectedTests(prev =>
      prev.includes(testId) ? prev.filter(id => id !== testId) : [...prev, testId]
    );
  };

  const hasChanges = JSON.stringify(localSelectedTests) !== JSON.stringify(selectedTests.map(test => test.id));

  const handleSave = async () => {
    if (!hasChanges) return;

    setSuccessMessage(null);
    try {
      await updateTestSchedule(localSelectedTests);
      setSuccessMessage('Test schedule updated successfully.');
    } catch (err) {
      console.error('Error saving test schedule:', err);
      // Error is now handled in the context, so we don't need to set it here
    }
  };

  return (
    <Sheet variant="outlined" sx={{ p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Combine Test Schedule</Typography>
      {error && <Alert color="danger" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert color="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      <List>
        {allTests.map((test) => (
          <ListItem key={test.id}>
            <ListItemButton 
              onClick={() => handleTestToggle(test.id)}
              disabled={!isEditingAllowed(combine?.status || '')}
            >
              <Checkbox 
                checked={localSelectedTests.includes(test.id)}
                label={test.name}
                disabled={!isEditingAllowed(combine?.status || '')}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button 
        onClick={handleSave}
        sx={{ mt: 2 }}
        disabled={!isEditingAllowed(combine?.status || '') || !hasChanges}
      >
        Save Test Schedule
      </Button>
      {!isEditingAllowed(combine?.status || '') && (
        <Typography 
          level="body-sm" 
          sx={{ mt: 2, color: 'text.secondary' }}
        >
          Test schedule updates are not allowed for completed combines
        </Typography>
      )}
    </Sheet>
  );
};

export default CombineTestSchedule;