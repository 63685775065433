import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Sheet, Tabs, TabList, Tab, TabPanel, CircularProgress, Alert } from '@mui/joy';
import CombineResults from '../components/CombineResults';
import CombineRoster from '../components/CombineRoster';
import CombineTestSchedule from '../components/CombineTestSchedule';
import CombineSessions from '../components/CombineSessions';
import CombineReport from '../components/CombineReport';
import { CombineDetailsProvider, useCombineDetails } from '../contexts/CombineDetailsContext';

const CombineDetailsContent: React.FC = () => {
  const { combineId } = useParams<{ combineId: string }>();
  const {
    combine,
    isLoading,
    error,
    usesSessions,
    loadCombineData,
  } = useCombineDetails();

  useEffect(() => {
    if (combineId) {
      loadCombineData(combineId);
    }
  }, [combineId, loadCombineData]);

  if (isLoading) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  if (error) {
    return (
      <Sheet sx={{ p: 4 }}>
        <Alert color="danger">{error}</Alert>
      </Sheet>
    );
  }

  if (!combine) {
    return (
      <Sheet sx={{ p: 4 }}>
        <Typography>Combine not found</Typography>
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 2 }}>
        {combine.name}
      </Typography>
      <Typography sx={{ mb: 4 }}>Date: {combine.date}</Typography>

      <Tabs defaultValue={0} sx={{ mb: 2 }}>
        <TabList>
          {usesSessions && <Tab>Sessions</Tab>}
          <Tab>Results</Tab>
          <Tab>Players</Tab>
          <Tab>Tests</Tab>
          <Tab>Reports</Tab>
        </TabList>
        {usesSessions && (
          <TabPanel value={0}>
            <CombineSessions combineId={combineId || ''} />
          </TabPanel>
        )}
        <TabPanel value={usesSessions ? 1 : 0}>
          <CombineResults />
        </TabPanel>
        <TabPanel value={usesSessions ? 2 : 1}>
          <CombineRoster />
        </TabPanel>
        <TabPanel value={usesSessions ? 3 : 2}>
          <CombineTestSchedule />
        </TabPanel>
        <TabPanel value={usesSessions ? 4 : 3}>
          <CombineReport />
        </TabPanel>
      </Tabs>
    </Sheet>
  );
};

const CombineDetails: React.FC = () => {
  return (
    <CombineDetailsProvider>
      <CombineDetailsContent />
    </CombineDetailsProvider>
  );
};

export default CombineDetails;