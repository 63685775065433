// src/components/PlayerReports.tsx

import React, { useRef } from 'react';
import { Sheet, Typography, Grid, List, ListItem, ListItemContent, Button, useColorScheme, useTheme, Box } from '@mui/joy';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Player, TestDefinition, AdvancedResult } from '../types/interfaces';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';



ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface PlayerReportProps {
  player: Player;
  advancedResults: AdvancedResult[];
  tests: TestDefinition[];
  totalPlayers: number;
}

const PlayerReports: React.FC<PlayerReportProps> = ({ player, advancedResults, tests, totalPlayers }) => {
  const reportRef = useRef<HTMLDivElement>(null);
  const { mode } = useColorScheme();
  const theme = useTheme();

  const playerResults = advancedResults.filter(r => r.playerId === player.id);

  const getBestAttribute = () => {
    if (playerResults.length === 0) return "No data available";
    const bestResult = playerResults.reduce((best, current) => 
      current.rank < best.rank ? current : best
    );
    const test = tests.find(t => t.id === bestResult.testId);
    return `${test?.name}: Rank ${bestResult.rank} out of ${totalPlayers}`;
  };

  const getWorstAttribute = () => {
    if (playerResults.length === 0) return "No data available";
    const worstResult = playerResults.reduce((worst, current) => 
      current.rank > worst.rank ? current : worst
    );
    const test = tests.find(t => t.id === worstResult.testId);
    return `${test?.name}: Rank ${worstResult.rank} out of ${totalPlayers}`;
  };

  const getOverallRank = () => {
    if (playerResults.length === 0) return "N/A";
    const averageRank = playerResults.reduce((sum, result) => sum + result.rank, 0) / playerResults.length;
    return Math.round(averageRank);
  };

  const getRankIcon = (rank: number) => {
    const iconStyle = { fontSize: '1.2rem', verticalAlign: 'middle' };
    if (rank === 1) return <EmojiEventsIcon sx={{ ...iconStyle, color: 'gold' }} />;
    if (rank === 2) return <EmojiEventsIcon sx={{ ...iconStyle, color: 'silver' }} />;
    if (rank === 3) return <EmojiEventsIcon sx={{ ...iconStyle, color: '#CD7F32' }} />; // Bronze color
    return null;
  };

  const radarData: ChartData<'radar'> = {
    labels: tests.map(test => test.name),
    datasets: [
      {
        label: 'Player Rank',
        data: tests.map(test => {
          const result = playerResults.find(r => r.testId === test.id);
          return result ? (totalPlayers + 1 - result.rank) : 0;
        }),
        backgroundColor: mode === 'dark' ? 'rgba(75, 192, 192, 0.2)' : 'rgba(75, 192, 192, 0.6)',
        borderColor: mode === 'dark' ? 'rgba(75, 192, 192, 0.8)' : 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
    ],
  };

  const radarOptions: ChartOptions<'radar'> = {
    scales: {
      r: {
        angleLines: {
          color: mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
        },
        grid: {
          color: mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
        },
        suggestedMin: 0,
        suggestedMax: totalPlayers,
        ticks: {
          stepSize: 1,
          color: mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.7)',
          font: {
            weight: 'bold' as const,
          },
          callback: function(tickValue: number | string, index: number, ticks: any[]): string | number {
            const value = typeof tickValue === 'number' ? tickValue : parseFloat(tickValue);
            return totalPlayers + 1 - value;
          },
          backdropColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        },
        pointLabels: {
          color: mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.7)',
          font: {
            weight: 'bold' as const,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.7)',
          font: {
            weight: 'bold' as const,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const invertedRank = context.raw as number;
            const actualRank = totalPlayers + 1 - invertedRank;
            return `Rank: ${actualRank}`;
          },
        },
      },
    },
  };


  const exportToPDF = async () => {
    if (reportRef.current) {
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        logging: false,
        useCORS: true,
        ignoreElements: (element) => element.classList.contains('no-export')
      });
      const imgData = canvas.toDataURL('image/png');
  
      // A4 size in mm
      const pdfWidth = 210;
      const pdfHeight = 297;
  
      // Standard A4 margins in mm
      const margin = 20;
  
      // Calculate content dimensions
      const contentWidth = pdfWidth - (2 * margin);
      const contentHeight = (contentWidth / canvas.width) * canvas.height;
  
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4'
      });
  
      // Convert theme color to RGB
      const backgroundColor = theme.palette.background.body;
      const rgb = parseColor(backgroundColor);
  
      if (rgb) {
        // Fill the background
        pdf.setFillColor(rgb.r, rgb.g, rgb.b);
        pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');
      }
  
      // Add the image with margins
      pdf.addImage(imgData, 'PNG', margin, margin, contentWidth, contentHeight);
      pdf.save(`${player.firstName}_${player.lastName}_report.pdf`);
    }
  };
  
  // Helper function to parse color
  const parseColor = (color: string) => {
    if (color.startsWith('rgb')) {
      const matches = color.match(/(\d+),\s*(\d+),\s*(\d+)/);
      if (matches) {
        return {
          r: parseInt(matches[1], 10),
          g: parseInt(matches[2], 10),
          b: parseInt(matches[3], 10)
        };
      }
    } else if (color.startsWith('#')) {
      const hex = color.replace('#', '');
      const bigint = parseInt(hex, 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255
      };
    }
    return null;
  };

  return (
    <Sheet 
      ref={reportRef} 
      variant="outlined"
      sx={{ 
        p: 3, 
        backgroundColor: theme.palette.background.body,
        color: theme.palette.text.primary,
        border: 'none',
      }}
    >
      <Typography level="h3" sx={{ mb: 2 }}>
        {player.firstName} {player.lastName} - Player Report
      </Typography>

      <Typography level="h4" sx={{ mb: 1 }}>Summary</Typography>
      <List>
        <ListItem>
          <ListItemContent>Best Attribute: {getBestAttribute()}</ListItemContent>
        </ListItem>
        <ListItem>
          <ListItemContent>Area for Improvement: {getWorstAttribute()}</ListItemContent>
        </ListItem>
        <ListItem>
          <ListItemContent>Overall Rank: {getOverallRank()} out of {totalPlayers}</ListItemContent>
        </ListItem>
      </List>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid xs={6}>
          <Typography level="h4" sx={{ mb: 1 }}>Test Rankings</Typography>
          <List>
  {tests.map(test => {
    const result = playerResults.find(r => r.testId === test.id);
    return (
      <ListItem key={test.id} sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemContent sx={{ display: 'flex', alignItems: 'center' }}>
          {test.name}: {result ? (
            <>
              {result.rank} out of {totalPlayers}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                {getRankIcon(result.rank)}
              </Box>
            </>
          ) : 'N/A'}
        </ListItemContent>
      </ListItem>
    );
  })}
</List>
        </Grid>
        <Grid xs={6}>
          <Radar data={radarData} options={radarOptions} />
        </Grid>
      </Grid>

      <Sheet sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }} className="no-export">
        <Button onClick={exportToPDF}>Export to PDF</Button>
      </Sheet>
    </Sheet>
  );
};

export default PlayerReports;