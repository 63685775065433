import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import App from './App';

console.log('REACT_APP_COGNITO_USER_POOL_ID:', process.env.REACT_APP_COGNITO_USER_POOL_ID);
console.log('REACT_APP_COGNITO_CLIENT_ID:', process.env.REACT_APP_COGNITO_CLIENT_ID);
console.log('REACT_APP_COGNITO_DOMAIN:', process.env.REACT_APP_COGNITO_DOMAIN);
console.log('REACT_APP_REDIRECT_SIGNIN:', process.env.REACT_APP_REDIRECT_SIGNIN);
console.log('REACT_APP_REDIRECT_SIGNOUT:', process.env.REACT_APP_REDIRECT_SIGNOUT)
console.log('REACT_APP_API_ENDPOINT:', process.env.REACT_APP_API_ENDPOINT)

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN as string,
          scopes: ['email', 'profile', 'openid'],
          redirectSignIn: ['https://measure-up-app.com', process.env.REACT_APP_REDIRECT_SIGNIN || window.location.origin],
          redirectSignOut: ['https://measure-up-app.com', process.env.REACT_APP_REDIRECT_SIGNOUT || window.location.origin],
          responseType: 'code'
        }
      }
    }
  },
  API: {
    REST: {
      'dev-MeasureUp-API': {
        endpoint: process.env.REACT_APP_API_ENDPOINT as string,
        region: process.env.REACT_APP_REGION as string,
      }
    }
  },
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);