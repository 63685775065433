import React, { useState } from 'react';
import { Modal, Typography, Button, Sheet, Box, Checkbox, FormControl } from '@mui/joy';
import { logUserAgreement } from '../api';
import { User } from '../types/interfaces';
import { EULA } from '../assets/EULA';

interface UserAgreementModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onAgreement: () => void;
  onDecline: () => void;
}

const UserAgreementModal: React.FC<UserAgreementModalProps> = ({ isOpen, onClose, user, onAgreement, onDecline }) => {
  const [isDigitalSignatureChecked, setIsDigitalSignatureChecked] = useState(false);

  const handleAgree = async () => {
    if (!isDigitalSignatureChecked) {
      alert("Please check the digital signature box to agree to the terms.");
      return;
    }

    try {
      // Log the user agreement
      await logUserAgreement({
        userId: user.id,
        version: EULA.version,
        agreedAt: new Date().toISOString(),
        userAgent: navigator.userAgent
      });

      onAgreement();
      onClose();
    } catch (error) {
      console.error('Failed to update user agreement status:', error);
      alert("An error occurred while processing your agreement. Please try again.");
    }
  };

  const handleDecline = () => {
    onDecline();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <Sheet
        variant="outlined"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <Typography id="modal-modal-title" level="h4" component="h2" sx={{ mb: 2 }}>
          Terms of Use for Measure Up Beta (Version: {EULA.version})
        </Typography>
        <Box 
          sx={{ 
            mt: 2, 
            mb: 2, 
            fontSize: '0.75rem', 
            lineHeight: 1.5,
            '& h2': {
              fontSize: '0.9rem',
              fontWeight: 'bold',
              mt: 2,
              mb: 1
            },
            '& p, & ol, & ul': {
              mt: 1,
              mb: 1
            }
          }}
          dangerouslySetInnerHTML={{ __html: EULA.content }}
        />
        <FormControl>
          <Checkbox
            label="I have read and agree to the terms above"
            checked={isDigitalSignatureChecked}
            onChange={(event) => setIsDigitalSignatureChecked(event.target.checked)}
          />
        </FormControl>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={handleDecline} color="danger">
            Decline and Log Out
          </Button>
          <Button onClick={handleAgree} disabled={!isDigitalSignatureChecked}>
            I Agree
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default UserAgreementModal;