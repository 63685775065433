import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Button, 
  Modal, 
  ModalDialog, 
  Typography, 
  Textarea, 
  Select, 
  Option, 
  Box, 
  IconButton, 
  CircularProgress,
  useTheme
} from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import { submitFeedback } from '../api';
import { Feedback } from '../types/interfaces';
import UAParser from 'ua-parser-js';

const FeedbackWidget: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFeedbackType('');
    setFeedbackText('');
    setSubmitError('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitError('');

    const uaParser = new UAParser();
    const browserInfo = uaParser.getBrowser();
    const osInfo = uaParser.getOS();
    const deviceInfo = uaParser.getDevice();

    const feedbackData: Feedback = {
      type: feedbackType,
      text: feedbackText,
      url: location.pathname,
      timestamp: new Date().toISOString(),
      details: {
        browser: {
            name: browserInfo.name,
            version: browserInfo.version,
            major: browserInfo.major,
          },
          os: {
            name: osInfo.name,
            version: osInfo.version,
          },
          device: {
            model: deviceInfo.model,
            type: deviceInfo.type,
            vendor: deviceInfo.vendor,
          },
        userAgent: navigator.userAgent,
        language: navigator.language,
        platform: navigator.platform,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        // Add any other metadata you want to capture
      }
    };

    try {
      const response = await submitFeedback(feedbackData);
      console.log('Feedback submitted:', response);
      handleClose();
      // Optionally, show a success message to the user
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitError('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getPlaceholderText = () => {
    switch (feedbackType) {
      case 'bug':
        return "Describe the bug you encountered. What were you trying to do? What happened instead? Any error messages?";
      case 'feature':
        return "Describe the new feature you'd like to see. How would it work? Why is it important to you?";
      case 'improvement':
        return "Which existing feature would you like to improve? How could it be better?";
      case 'hi':
        return "Just saying hi? Feel free to share any thoughts, praise, or general feedback!";
      case 'other':
        return "Share your thoughts, ideas, or feedback here. We're all ears!";
      default:
        return "Describe your feedback here...";
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        variant="solid"
        onClick={handleOpen}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          borderRadius: '50%',
        }}
      >
        <FeedbackIcon />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          aria-labelledby="feedback-modal-title"
          aria-describedby="feedback-modal-description"
          sx={{
            maxWidth: isMobile ? '90%' : '600px',
            width: '100%',
            height: isMobile ? '90%' : 'auto',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Typography id="feedback-modal-title" component="h2" level="h4" mb={1}>
            Provide Feedback
          </Typography>
          <Typography id="feedback-modal-description" level="body-md" mb={3}>
            Help make Measure Up a better app. Your feedback is valuable to us and will be used to improve the app experience.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <Select
              placeholder="Select feedback type"
              value={feedbackType}
              onChange={(_, newValue) => setFeedbackType(newValue as string)}
              sx={{ mb: 2, width: '100%' }}
            >
              <Option value="bug">Bug Report</Option>
              <Option value="feature">Feature Request</Option>
              <Option value="improvement">Improvement Suggestion</Option>
              <Option value="hi">Just saying 'Hi'</Option>
              <Option value="other">Other</Option>
            </Select>
            <Textarea
              placeholder={getPlaceholderText()}
              minRows={5}
              maxRows={10}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              sx={{ mb: 2, width: '100%' }}
            />
            {submitError && (
              <Typography color="danger" sx={{ mb: 2 }}>
                {submitError}
              </Typography>
            )}
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button onClick={handleClose} color="neutral" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting || !feedbackType || !feedbackText}>
                {isSubmitting ? <CircularProgress size="sm" /> : 'Submit Feedback'}
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default FeedbackWidget;