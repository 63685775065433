import React, { useState, useRef } from 'react';
import { Button, Modal, Typography, List, ListItem, ModalDialog, Box, CircularProgress, Switch, Sheet } from '@mui/joy';
import { Player } from '../types/interfaces';
import { checkExistingPlayers, bulkCreatePlayers, addPlayersToCombine } from '../api';
import Papa from 'papaparse';

interface PlayerImportValidatorProps {
  combineId: string;
  onPlayersAdded: (players: Player[]) => void;
  isEditingAllowed: boolean;
}

interface ExistingCheckResult {
  input: Player;
  match: Player | null;
  action: 'match' | 'create';
  useExisting: boolean;
}

const PlayerImportValidator: React.FC<PlayerImportValidatorProps> = ({ combineId, onPlayersAdded, isEditingAllowed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingCheckResults, setExistingCheckResults] = useState<ExistingCheckResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState<'upload' | 'check-existing' | 'confirm'>('upload');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setIsLoading(true);
      setError(null);
      const file = event.target.files[0];
      
      Papa.parse<Player>(file, {
        header: true,
        complete: async (results) => {
          try {
            const checkResponse = await checkExistingPlayers(results.data);
            setExistingCheckResults(checkResponse.data.map((result) => ({
              input: result.input as Player,
              match: result.match,
              action: result.action,
              useExisting: result.action === 'match'
            })));
            setStep('check-existing');
            setIsModalOpen(true);
          } catch (error) {
            console.error('Error checking existing players:', error);
            setError('Failed to check existing players. Please try again.');
          } finally {
            setIsLoading(false);
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          setError('Failed to parse CSV file. Please check the file format and try again.');
          setIsLoading(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      });
    }
  };

  const handleToggle = (index: number) => {
    setExistingCheckResults(prevResults =>
      prevResults.map((result, i) =>
        i === index ? { ...result, useExisting: !result.useExisting } : result
      )
    );
  };

  const handleConfirmExistingCheck = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const playersToCreate = existingCheckResults
        .filter(result => !result.useExisting)
        .map(result => result.input);

      const createdPlayersResponse = await bulkCreatePlayers(playersToCreate);
      const createdPlayers = createdPlayersResponse.data;
      
      const finalPlayers = existingCheckResults.map(result => 
        result.useExisting ? result.match! : 
        createdPlayers.find((p: Player) => 
          p.firstName === result.input.firstName && 
          p.lastName === result.input.lastName && 
          p.dateOfBirth === result.input.dateOfBirth
        )
      ).filter((player): player is Player => player !== undefined);

      setExistingCheckResults(existingCheckResults.map((result, index) => ({
        ...result,
        match: finalPlayers[index]
      })));

      setStep('confirm');
    } catch (error) {
      console.error('Error creating players:', error);
      setError('Failed to create new players. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToCombine = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const playerIds = existingCheckResults
        .map(result => result.match?.id)
        .filter((id): id is string => id !== undefined);
  
      const response = await addPlayersToCombine(combineId, { players: playerIds });
      
      // Assuming the response includes the full player objects
      const addedPlayers = response.data.players as Player[];
  
      onPlayersAdded(addedPlayers);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding players to combine:', error);
      setError('Failed to add players to combine. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        disabled={isLoading || !isEditingAllowed}
      >
        {isLoading ? <CircularProgress size="sm" /> : "Upload Roster"}
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </Button>

      {error && (
        <Typography color="danger" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

<Modal 
  open={isModalOpen} 
  onClose={() => {
    setIsModalOpen(false);
    setStep('upload');
    setExistingCheckResults([]);
    setError(null);
  }}
>
  <ModalDialog sx={{ maxWidth: 500, maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
    <Typography level="h4" sx={{ mb: 2 }}>
      {step === 'check-existing' ? 'Check for Existing Players' : 'Confirm Players to Add'}
    </Typography>
    <Sheet 
      sx={{ 
        flex: 1,
        overflowY: 'auto', 
        mb: 2,
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        }
      }}
    >
      <List>
        {existingCheckResults.map((result, index) => (
          <ListItem key={index}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography>{`${result.input.lastName}, ${result.input.firstName}`}</Typography>
              <Typography level="body-sm">{`Position: ${result.input.position}, DOB: ${result.input.dateOfBirth}`}</Typography>
            </Box>
            {step === 'check-existing' && result.match && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography level="body-sm" sx={{ mr: 2 }}>
                  {result.useExisting ? 'Use Existing' : 'Create New'}
                </Typography>
                <Switch
                  checked={result.useExisting}
                  onChange={() => handleToggle(index)}
                />
              </Box>
            )}
            {step === 'check-existing' && !result.match && (
              <Typography level="body-sm" color="neutral">New Player</Typography>
            )}
            {step === 'confirm' && (
              <Typography level="body-sm" color="primary">
                {result.useExisting ? 'Existing Player' : 'New Player'}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Sheet>
    {step === 'check-existing' && (
      <Button onClick={handleConfirmExistingCheck} sx={{ mt: 2 }}>
        Confirm and Add to Database
      </Button>
    )}
    {step === 'confirm' && (
      <Button onClick={handleAddToCombine} sx={{ mt: 2 }}>
        Add Players to Combine
      </Button>
    )}
  </ModalDialog>
</Modal>
    </>
  );
};

export default PlayerImportValidator;