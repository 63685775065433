import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Input, Grid } from '@mui/joy';
import { TestDefinition, TestResult, Player } from '../types/interfaces';
import BackspaceIcon from '@mui/icons-material/Backspace';
import RestoreIcon from '@mui/icons-material/Restore';

interface NumberPadProps {
  onInput: (value: string) => void;
  onReset: () => void;
  precision: number;
}

const NumberPad: React.FC<NumberPadProps> = ({ onInput, onReset, precision }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        {['7', '8', '9', '4', '5', '6', '1', '2', '3'].map((btn) => (
          <Grid key={btn} xs={4}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onInput(btn)}
              sx={{ height: '48px', fontSize: '1.2rem' }}
            >
              {btn}
            </Button>
          </Grid>
        ))}
        <Grid xs={6}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onInput('0')}
            sx={{ height: '48px', fontSize: '1.2rem' }}
          >
            0
          </Button>
        </Grid>
        <Grid xs={6}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onInput('.')}
            disabled={precision === 0}
            sx={{ height: '48px', fontSize: '1.2rem' }}
          >
            .
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={4}>
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            onClick={() => onInput('Clear')}
            sx={{ height: '48px', fontSize: '1rem' }}
          >
            Clear
          </Button>
        </Grid>
        <Grid xs={4}>
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            onClick={() => onInput('Backspace')}
            sx={{ height: '48px', fontSize: '1rem' }}
            startDecorator={<BackspaceIcon />}
          >
            Backspace
          </Button>
        </Grid>
        <Grid xs={4}>
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            onClick={onReset}
            sx={{ height: '48px', fontSize: '1rem' }}
            startDecorator={<RestoreIcon />}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

interface MeasurementCardProps {
  currentTest: TestDefinition;
  currentPlayer: Player;
  currentValue: string;
  testResult: TestResult | null;
  onNumberInput: (value: string) => void;
  onSave: () => void;
}

const MeasurementCard: React.FC<MeasurementCardProps> = ({
  currentTest,
  currentPlayer,
  currentValue,
  testResult,
  onNumberInput,
  onSave,
}) => {
  const [savedValue, setSavedValue] = useState(testResult?.value.toString() || '');

  useEffect(() => {
    setSavedValue(testResult?.value.toString() || '');
  }, [testResult, currentTest.id, currentPlayer.id]);

  const handleInput = (value: string) => {
    if (value === 'Clear') {
      onNumberInput('');
    } else if (value === 'Backspace') {
      onNumberInput(currentValue.slice(0, -1));
    } else {
      onNumberInput(currentValue + value);
    }
  };

  const handleReset = () => {
    onNumberInput(savedValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
      <Typography level="h3" sx={{ mb: 1 }}>
        {currentPlayer.firstName} {currentPlayer.lastName}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
        <Typography level="body-md" sx={{ mr: 1 }}>
          Current Value:
        </Typography>
        <Typography
          level="h4"
          sx={{
            color: testResult ? 'success.500' : 'neutral.500',
            transition: 'color 0.3s',
          }}
        >
          {currentValue || '---'} {currentTest.units}
        </Typography>
      </Box>
      <Input
        value={currentValue}
        onChange={(e) => onNumberInput(e.target.value)}
        placeholder={`Enter ${currentTest.name}`}
        sx={{ mb: 1, fontSize: '1.2rem' }}
      />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: 0 }}>
        <NumberPad onInput={handleInput} onReset={handleReset} precision={currentTest.precision} />
      </Box>
    </Box>
  );
};

export default MeasurementCard;