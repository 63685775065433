// src/components/Layout.tsx
import React, { useState, useEffect, useRef } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Sheet from '@mui/joy/Sheet';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/muHiDef.png';
import FeedbackWidget from './FeedbackWidget';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="primary"
      onClick={() => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
      }}
    >
      {mode === 'light' ? <ModeNightRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

interface LayoutProps {
  children: React.ReactNode;
  signOut?: () => void;
}

export default function Layout({ children, signOut }: LayoutProps) {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const navItems = [
    { name: 'Dashboard', path: '/' },
    { name: 'Combines', path: '/combines' },
    { name: 'Players', path: '/players' },
    { name: 'Tests', path: '/tests' },
    { name: 'Organizations', path: '/organizations' },
    { name: 'Settings', path: '/settings' },
  ];

  const handleSignOut = () => {
    if (signOut) {
      signOut();
      navigate('/login');
    }
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setSidebarVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Sheet
          component="nav"
          className="Sidebar"
          ref={sidebarRef}
          sx={{
            display: {
              xs: sidebarVisible ? 'flex' : 'none',
              lg: 'flex',
            },
            flexDirection: 'column',
            gap: 2,
            width: 240,
            height: '100dvh',
            p: 2,
            boxSizing: 'border-box',
            position: {
              xs: 'fixed',
              md: 'sticky',
            },
            top: 0,
            zIndex: 9999,
            borderRight: '1px solid',
            borderColor: 'divider',
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <img src={logo} alt="Measure Up Logo" style={{ width: '50%', maxWidth: '150px', borderRadius: 4 }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ColorSchemeToggle />
          </Box>
          <List size="sm" sx={{ flexGrow: 1 }}>
            {navItems.map((item) => (
              <ListItem key={item.name}>
                <ListItemButton
                  component={RouterLink}
                  to={item.path}
                  selected={location.pathname === item.path}
                >
                  <ListItemContent>{item.name}</ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {signOut && (
            <ListItem>
              <ListItemButton onClick={handleSignOut} color="neutral">
                <LogoutIcon sx={{ mr: 1 }} />
                <ListItemContent>Logout</ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
        </Sheet>
        <Box
          component="main"
          className="Main"
          sx={{
            flexGrow: 1,
            minWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 1100,
              height: 'var(--Header-height)',
              p: 2,
              gap: 1,
              bgcolor: 'background.surface',
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <IconButton
              onClick={toggleSidebar}
              variant="outlined"
              color="neutral"
              size="sm"
              sx={{ display: { xs: 'inline-flex', lg: 'none' } }}
            >
              <MenuRoundedIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>{children}</Box>
          <FeedbackWidget />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}