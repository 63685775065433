import React, { useState, useEffect } from 'react';
import {
  Sheet,
  Typography,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  CircularProgress,
  IconButton,
  Box
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Player, Combine, TestResult, TestDefinition } from '../types/interfaces';
import { getPlayer, getCombinesByPlayer, getTestResultsByPlayer, getAllTestDefinitions } from '../api';
import PlayerDetails from '../components/PlayerDetails';
import PlayerCombines from '../components/PlayerCombines';
import PlayerResults from '../components/PlayerResults';

interface PlayerPageProps {
  playerId: string;
  onClose: () => void;
}

const PlayerPage: React.FC<PlayerPageProps> = ({ playerId, onClose }) => {
  const [player, setPlayer] = useState<Player | null>(null);
  const [combines, setCombines] = useState<Combine[]>([]);
  const [results, setResults] = useState<TestResult[]>([]);
  const [testDefinitions, setTestDefinitions] = useState<TestDefinition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [playerData, combinesData, resultsData, testDefinitionsData] = await Promise.all([
          getPlayer(playerId),
          getCombinesByPlayer(playerId),
          getTestResultsByPlayer(playerId),
          getAllTestDefinitions()
        ]);

        setPlayer(playerData.data);
        setCombines(combinesData);
        setResults(resultsData.data);
        setTestDefinitions(testDefinitionsData.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [playerId]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !player) {
    return (
      <Box sx={{ p: 2, width: '100%' }}>
        <Typography color="danger">{error || 'Player not found'}</Typography>
      </Box>
    );
  }

  return (
    <Sheet
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        p: 2,
        boxSizing: 'border-box',
        maxWidth: '100%', // Ensure it doesn't exceed the drawer width
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={onClose} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography level="h1">{`${player.firstName} ${player.lastName}`}</Typography>
      </Box>
      <Tabs aria-label="Player Information Tabs" defaultValue={0} sx={{ mb: 2 }}>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Combines</Tab>
          <Tab>Results</Tab>
        </TabList>
        <TabPanel value={0}>
          <PlayerDetails player={player} />
        </TabPanel>
        <TabPanel value={1}>
          <PlayerCombines combines={combines} />
        </TabPanel>
        <TabPanel value={2}>
          <PlayerResults
            results={results}
            testDefinitions={testDefinitions}
            combines={combines}
          />
        </TabPanel>
      </Tabs>
    </Sheet>
  );
};

export default PlayerPage;