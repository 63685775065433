// AddPlayerModal.tsx
import React, { useState } from 'react';
import { Modal, ModalDialog, ModalClose, Typography, Button, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import { Player } from '../types/interfaces';

interface AddPlayerModalProps {
  open: boolean;
  onClose: () => void;
  onAddPlayer: (player: Partial<Player>) => void;
}

const AddPlayerModal: React.FC<AddPlayerModalProps> = ({ open, onClose, onAddPlayer }) => {
  const [playerData, setPlayerData] = useState<Partial<Player>>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    picture: '',
    position: '',
    team: '',
    nickname: '',
    notes: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setPlayerData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAddPlayer(playerData);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Typography level="h4">Add New Player</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel>First Name*</FormLabel>
            <Input
              name="firstName"
              value={playerData.firstName}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name*</FormLabel>
            <Input
              name="lastName"
              value={playerData.lastName}
              onChange={handleInputChange}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Date of Birth</FormLabel>
            <Input
              name="dateOfBirth"
              type="date"
              value={playerData.dateOfBirth}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Picture URL</FormLabel>
            <Input
              name="picture"
              value={playerData.picture}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Position</FormLabel>
            <Input
              name="position"
              value={playerData.position}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Team</FormLabel>
            <Input
              name="team"
              value={playerData.team}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Nickname</FormLabel>
            <Input
              name="nickname"
              value={playerData.nickname}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Notes</FormLabel>
            <Textarea
              name="notes"
              value={playerData.notes}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button type="submit" sx={{ mt: 2 }}>Add Player</Button>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default AddPlayerModal;