import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Sheet } from '@mui/joy';

interface LoginProps {
  signOut?: () => void;
  user?: any;
}

function Login({ signOut, user }: LoginProps) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      navigate('/combines');
    }
  }, [user, navigate]);

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>
        Welcome, {user?.username}
      </Typography>
      {signOut && (
        <Button 
          onClick={signOut}
          color="danger"
          variant="solid"
        >
          Sign out
        </Button>
      )}
    </Sheet>
  );
}

export default Login;
