import React, { useState, useMemo } from 'react';
import {
  Sheet,
  Typography,
  Table,
  Button,
  useColorScheme
} from '@mui/joy';
import { Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip as ChartTooltip, 
  Legend
} from 'chart.js';
import { TestResult, TestDefinition, Combine } from '../types/interfaces';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  ChartTooltip, 
  Legend
);

interface PlayerResultsProps {
  results: TestResult[];
  testDefinitions: TestDefinition[];
  combines: Combine[];
}

interface EnhancedTestResult extends TestResult {
  testDefinition: TestDefinition;
  combineName: string;
}

interface GroupedResult {
  combineId: string;
  combineName: string;
  date: string;
  results: { [testId: string]: EnhancedTestResult };
}

const colorPalette = [
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
  '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
];

const PlayerResults: React.FC<PlayerResultsProps> = ({ results, testDefinitions, combines }) => {
  const [sortColumn, setSortColumn] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const { mode } = useColorScheme();

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const enhancedResults = useMemo(() => {
    const testDefinitionsMap = new Map(testDefinitions.map(def => [def.id, def]));
    const combinesMap = new Map(combines.map(combine => [combine.id, combine]));

    return results.map(result => ({
      ...result,
      testDefinition: testDefinitionsMap.get(result.testId)!,
      combineName: combinesMap.get(result.combineId)?.name || 'Unknown Combine'
    }));
  }, [results, testDefinitions, combines]);

  const uniqueTests = useMemo(() => {
    const testIds = new Set(enhancedResults.map(result => result.testId));
    return testDefinitions
      .filter(def => testIds.has(def.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [enhancedResults, testDefinitions]);

  const groupedResults = useMemo(() => {
    const grouped: { [combineId: string]: GroupedResult } = {};
    enhancedResults.forEach(result => {
      if (!grouped[result.combineId]) {
        grouped[result.combineId] = {
          combineId: result.combineId,
          combineName: result.combineName,
          date: result.timestamp.split('T')[0],
          results: {}
        };
      }
      grouped[result.combineId].results[result.testId] = result;
    });
    return Object.values(grouped);
  }, [enhancedResults]);

  const sortedGroupedResults = useMemo(() => {
    return [...groupedResults].sort((a, b) => {
      if (sortColumn === 'combine') {
        return sortDirection === 'asc' 
          ? a.combineName.localeCompare(b.combineName)
          : b.combineName.localeCompare(a.combineName);
      } else if (sortColumn === 'date') {
        return sortDirection === 'asc'
          ? a.date.localeCompare(b.date)
          : b.date.localeCompare(a.date);
      } else {
        const valueA = a.results[sortColumn]?.value || -Infinity;
        const valueB = b.results[sortColumn]?.value || -Infinity;
        return sortDirection === 'asc' 
          ? (valueA as number) - (valueB as number)
          : (valueB as number) - (valueA as number);
      }
    });
  }, [groupedResults, sortColumn, sortDirection]);

  const chartData = useMemo(() => {
    const colorMap = new Map(uniqueTests.map((test, index) => [test.id, colorPalette[index % colorPalette.length]]));
    
    const datasets = uniqueTests.map(test => ({
      label: test.name,
      data: sortedGroupedResults.map(gr => ({
        x: gr.date,
        y: gr.results[test.id]?.value || null,
        testResult: gr.results[test.id]
      })),
      borderColor: colorMap.get(test.id),
      backgroundColor: colorMap.get(test.id),
      fill: false,
      yAxisID: test.units === 'seconds' ? 'y-seconds' : 'y-other'
    }));

    return {
      labels: sortedGroupedResults.map(gr => gr.date),
      datasets
    };
  }, [sortedGroupedResults, uniqueTests]);

  const chartOptions = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Player Test Results Over Time',
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const datasetLabel = context.dataset.label || '';
            const value = context.parsed.y;
            const testResult = context.raw.testResult;
            const units = testResult?.testDefinition.units || '';
            return `${datasetLabel}: ${value} ${units}`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'category' as const,
        title: {
          display: true,
          text: 'Combine Date'
        },
        offset: sortedGroupedResults.length === 1,
        ticks: {
          align: sortedGroupedResults.length === 1 ? 'center' as const : 'start' as const
        }
      },
      'y-seconds': {
        type: 'linear' as const,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Seconds'
        },
        reverse: true
      },
      'y-other': {
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Other Units'
        }
      }
    }
  }), [sortedGroupedResults]);

  return (
    <Sheet variant="outlined" sx={{ p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Player Results</Typography>

      {uniqueTests.length > 0 ? (
        <>
          <Sheet variant="soft" sx={{ p: 2, mb: 4 }}>
            <Line data={chartData} options={chartOptions} />
          </Sheet>

          <Sheet sx={{ overflow: 'auto' }}>
            <Table
              stickyHeader
              sx={{
                tableLayout: 'fixed',
                '& th, & td': {
                  padding: '8px',
                  verticalAlign: 'middle',
                  borderBottom: '1px solid',
                  borderColor: mode === 'light' ? 'neutral.200' : 'neutral.700',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                '& th': {
                  borderTop: '1px solid',
                  borderColor: mode === 'light' ? 'neutral.200' : 'neutral.700',
                  width: '90px', // Fixed width for all columns
                },
                '& td': {
                  textAlign: 'center',
                },
                '& th:first-of-type, & th:nth-of-type(2)': {
                  width: '120px', // Slightly wider for Combine and Date columns
                },
              }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>
                    <Button
                      variant="plain"
                      onClick={() => handleSort('combine')}
                      endDecorator={sortColumn === 'combine' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                      sx={{ justifyContent: 'flex-start' }}
                    >
                      Combine
                    </Button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <Button
                      variant="plain"
                      onClick={() => handleSort('date')}
                      endDecorator={sortColumn === 'date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                      sx={{ justifyContent: 'flex-start' }}
                    >
                      Date
                    </Button>
                  </th>
                  {uniqueTests.map(test => (
                    <th key={test.id}>
                      <Button
                        variant="plain"
                        onClick={() => handleSort(test.id)}
                        endDecorator={sortColumn === test.id && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                        title={test.name}
                        sx={{ 
                          justifyContent: 'center',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {test.shortName}
                      </Button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedGroupedResults.map((groupedResult, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>{groupedResult.combineName}</td>
                    <td style={{ textAlign: 'left' }}>{groupedResult.date}</td>
                    {uniqueTests.map(test => (
                      <td key={test.id}>
                        {groupedResult.results[test.id]?.value || ''}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </>
      ) : (
        <Typography level="body-lg" sx={{ textAlign: 'center', my: 4 }}>
          No test results available for this player.
        </Typography>
      )}
    </Sheet>
  );
};

export default PlayerResults;