import React, { useState } from 'react';
import { Sheet, Typography, Table, Button } from '@mui/joy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Combine } from '../types/interfaces';

interface PlayerCombinesProps {
  combines: Combine[];
}

const PlayerCombines: React.FC<PlayerCombinesProps> = ({ combines }) => {
  const [sortColumn, setSortColumn] = useState<keyof Combine>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const handleSort = (column: keyof Combine) => {
    if (sortColumn === column) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedCombines = [...combines].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];
    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return 1;
    if (bValue == null) return -1;
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  if (combines.length === 0) return <Typography>No combines found for this player.</Typography>;

  return (
    <Sheet>
      <Typography level="h2">Player Combines</Typography>
      <Table>
        <thead>
          <tr>
            {['name', 'date', 'location'].map((column) => (
              <th key={column}>
                <Button
                  variant="plain"
                  onClick={() => handleSort(column as keyof Combine)}
                  endDecorator={sortColumn === column && (
                    sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
                  )}
                >
                  {column.charAt(0).toUpperCase() + column.slice(1)}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedCombines.map((combine) => (
            <tr key={combine.id}>
              <td>{combine.name}</td>
              <td>{(combine.date)}</td>
              <td>{combine.location || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
};

export default PlayerCombines;