// src/pages/PlayerRollCall.tsx

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Button, 
  Checkbox, 
  List, 
  ListItem, 
  ListItemButton, 
  Typography, 
  Sheet,
  Grid,
  Card,
  CardContent,
  AspectRatio,
  Switch,
  Box
} from '@mui/joy';
import { Player } from '../types/interfaces';
import { players } from '../localData';

const PlayerRollCall: React.FC = () => {
  const { combineId } = useParams<{ combineId: string }>();
  const navigate = useNavigate();
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'list' | 'tile'>('list');

  const handlePlayerToggle = (playerId: string) => {
    setSelectedPlayers(prev =>
      prev.includes(playerId) ? prev.filter(id => id !== playerId) : [...prev, playerId]
    );
  };

  const handleContinue = () => {
    navigate(`/combines/${combineId}/test-selection?players=${selectedPlayers.join(',')}`);
  };

  const renderListView = () => (
    <List>
      {players.map((player: Player) => (
        <ListItem key={player.id}>
          <ListItemButton>
            <Checkbox 
              checked={selectedPlayers.includes(player.id)}
              onChange={() => handlePlayerToggle(player.id)}
              label={`${player.firstName} ${player.lastName}`}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  const renderTileView = () => (
    <Grid container spacing={2}>
      {players.map((player: Player) => (
        <Grid key={player.id} xs={12} sm={6} md={4} lg={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              cursor: 'pointer',
              bgcolor: selectedPlayers.includes(player.id) ? 'primary.100' : 'background.body'
            }}
            onClick={() => handlePlayerToggle(player.id)}
          >
            <AspectRatio ratio="1">
              <img
                src={player.picture}
                alt={`${player.firstName} ${player.lastName}`}
                loading="lazy"
              />
            </AspectRatio>
            <CardContent>
              <Checkbox 
                checked={selectedPlayers.includes(player.id)}
                onChange={() => handlePlayerToggle(player.id)}
                label={`${player.firstName} ${player.lastName}`}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold' }}>Player Roll Call</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 1 }}>List</Typography>
          <Switch 
            checked={viewMode === 'tile'}
            onChange={() => setViewMode(prev => prev === 'list' ? 'tile' : 'list')}
          />
          <Typography sx={{ ml: 1 }}>Tile</Typography>
        </Box>
      </Box>
      {viewMode === 'list' ? renderListView() : renderTileView()}
      <Button 
        onClick={handleContinue}
        color="primary"
        variant="solid"
        sx={{ mt: 4 }}
        disabled={selectedPlayers.length === 0}
      >
        Continue to Test Selection ({selectedPlayers.length} players selected)
      </Button>
    </Sheet>
  );
};

export default PlayerRollCall;