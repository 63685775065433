// src/components/ResultsImportModal.tsx

import React, { useState, useEffect } from 'react';
import { Modal, ModalDialog, Typography, Button, Box, CircularProgress, Select, Option } from '@mui/joy';
import Papa from 'papaparse';
import { TestDefinition, TestResult, Player } from '../types/interfaces';
import { getAllTestDefinitions, bulkAddTestResults } from '../api';
import Dropzone from './Dropzone';

interface ResultsImportModalProps {
    open: boolean;
    onClose: () => void;
    combineId: string;
    players: Player[];
    onImportComplete: (results: Partial<TestResult>[]) => void;
}

interface CSVRow {
    [key: string]: string;
}

const ResultsImportModal: React.FC<ResultsImportModalProps> = ({
    open,
    onClose,
    combineId,
    players,
    onImportComplete,
}) => {
    const [step, setStep] = useState(1);
    const [csvData, setCsvData] = useState<CSVRow[]>([]);
    const [columnMapping, setColumnMapping] = useState<{ [key: string]: string }>({});
    const [testDefinitions, setTestDefinitions] = useState<TestDefinition[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const resetState = () => {
        setStep(1);
        setCsvData([]);
        setColumnMapping({});
        setError(null);
    };

    const handleClose = () => {
        resetState();
        onClose();
    };


    useEffect(() => {
        if (open) {
            resetState();
            const fetchTestDefinitions = async () => {
                setIsLoading(true);
                try {
                    const response = await getAllTestDefinitions();
                    setTestDefinitions(response.data);
                } catch (err) {
                    setError('Failed to fetch test definitions. Please try again.');
                    console.error(err);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchTestDefinitions();
        }
    }, [open]);

    const handleFileUpload = (file: File) => {
        Papa.parse<CSVRow>(file, {
            complete: (results) => {
                console.log('Parsed CSV results:', results);
                setCsvData(results.data);
                if (results.data.length > 0) {
                    generateInitialMapping(Object.keys(results.data[0]));
                }
                setStep(2);
            },
            header: true,
            skipEmptyLines: true,
        });
    };

    const generateInitialMapping = (headers: string[]) => {
        console.log('Generating initial mapping for headers:', headers);
        const mapping: { [key: string]: string } = {};
        const ignoredColumns: string[] = [];

        const normalizeString = (str: string) =>
            str.toLowerCase().replace(/[^a-z0-9]/g, '');

        const expandAbbreviations = (str: string) =>
            str.replace(/yd/g, 'yard').replace(/m/g, 'meter');

        const normalizeNumbers = (str: string) =>
            str.replace(/(\d+)([a-z]+)/g, '$1 $2');

        headers.forEach((header) => {
            const normalizedHeader = normalizeString(header);
            if (normalizedHeader === 'firstname' || normalizedHeader === 'lastname') {
                mapping[header] = normalizedHeader;
            } else {
                const processedHeader = expandAbbreviations(normalizeNumbers(normalizedHeader));
                const matchedTest = testDefinitions.find((test) => {
                    const processedTestName = expandAbbreviations(normalizeNumbers(normalizeString(test.name)));
                    const processedShortName = expandAbbreviations(normalizeNumbers(normalizeString(test.shortName)));

                    return processedTestName === processedHeader ||
                        processedShortName === processedHeader ||
                        processedTestName.includes(processedHeader) ||
                        processedHeader.includes(processedTestName) ||
                        processedShortName.includes(processedHeader) ||
                        processedHeader.includes(processedShortName);
                });

                if (matchedTest) {
                    mapping[header] = matchedTest.id;
                } else {
                    mapping[header] = 'ignore';
                    ignoredColumns.push(header);
                }
            }
        });
        console.log('Generated mapping:', mapping);
        console.log('Ignored columns:', ignoredColumns);
        setColumnMapping(mapping);
    };

    const handleMappingChange = (csvColumn: string, testId: string) => {
        setColumnMapping((prev) => ({ ...prev, [csvColumn]: testId }));
    };

    const handleImport = async () => {
        setIsLoading(true);
        setError(null);

        try {
            console.log('CSV Data:', csvData);
            console.log('Column Mapping:', columnMapping);
            console.log('Players:', players);

            const resultsToImport: Partial<TestResult>[] = csvData.flatMap((row) => {
                console.log('Processing row:', row);
                const firstNameKey = Object.keys(columnMapping).find(key => columnMapping[key] === 'firstname');
                const lastNameKey = Object.keys(columnMapping).find(key => columnMapping[key] === 'lastname');

                if (!firstNameKey || !lastNameKey) {
                    console.log('Could not find firstName or lastName columns');
                    return [];
                }

                const player = players.find(
                    (p) =>
                        p.firstName.toLowerCase() === row[firstNameKey].toLowerCase() &&
                        p.lastName.toLowerCase() === row[lastNameKey].toLowerCase()
                );

                if (!player) {
                    console.log('No matching player found for:', row[firstNameKey], row[lastNameKey]);
                    return [];
                }

                console.log('Matched player:', player);

                return Object.entries(columnMapping)
                    .filter(([_, value]) => value !== 'firstname' && value !== 'lastname' && value !== 'ignore')
                    .map(([csvColumn, testId]) => {
                        const result: Partial<TestResult> = {
                            combineId,
                            playerId: player.id,
                            testId,
                            value: parseFloat(row[csvColumn]),
                            timestamp: new Date().toISOString(),
                        };
                        console.log('Created result:', result);
                        return result;
                    });
            });

            console.log('Final resultsToImport:', resultsToImport);

            if (resultsToImport.length === 0) {
                throw new Error('No valid results to import');
            }

            const response = await bulkAddTestResults(combineId, resultsToImport);
            console.log(response)

            onImportComplete(resultsToImport);
            onClose();
        } catch (err) {
            console.error('Error details:', err);
            setError('Failed to import results. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog sx={{ minWidth: 400, maxWidth: 600 }}>
                <Typography level="h4" mb={2}>
                    Import Test Results
                </Typography>
                {step === 1 && (
                    <Box>
                        <Typography mb={2}>Upload a CSV file with test results</Typography>
                        <Dropzone onFileAccepted={handleFileUpload} />
                    </Box>
                )}
                {step === 2 && (
                    <Box>
                        <Typography mb={2}>Map CSV columns to tests</Typography>
                        {Object.keys(columnMapping).map((csvColumn) => (
                            <Box key={csvColumn} display="flex" alignItems="center" mb={1}>
                                <Typography flex={1}>{csvColumn}</Typography>
                                <Select
                                    value={columnMapping[csvColumn]}
                                    onChange={(_, value) => handleMappingChange(csvColumn, value as string)}
                                >
                                    <Option value="ignore">Ignore Column</Option>
                                    {columnMapping[csvColumn] === 'firstname' && <Option value="firstname">First Name</Option>}
                                    {columnMapping[csvColumn] === 'lastname' && <Option value="lastname">Last Name</Option>}
                                    {testDefinitions.map((test) => (
                                        <Option key={test.id} value={test.id}>
                                            {test.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Box>
                        ))}
                        <Button onClick={handleImport} disabled={isLoading}>
                            {isLoading ? <CircularProgress size="sm" /> : 'Import Results'}
                        </Button>
                    </Box>
                )}
                {error && (
                    <Typography color="danger" mt={2}>
                        {error}
                    </Typography>
                )}
            </ModalDialog>
        </Modal>
    );
};

export default ResultsImportModal;