// src/components/Dropzone.tsx

import React, { useCallback } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { Box, Typography } from '@mui/joy';

interface DropzoneProps {
  onFileAccepted: (file: File) => void;
  accept?: Accept;
  maxSize?: number;
}

const Dropzone: React.FC<DropzoneProps> = ({ 
  onFileAccepted, 
  accept = { 'text/csv': ['.csv'] },
  maxSize = 5242880 
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onFileAccepted(acceptedFiles[0]);
    }
  }, [onFileAccepted]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop, 
    accept,
    maxSize,
    multiple: false
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed grey',
        borderRadius: 2,
        p: 2,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography>Drop the file here ...</Typography>
      ) : (
        <Typography>Drag 'n' drop a file here, or click to select a file</Typography>
      )}
    </Box>
  );
};

export default Dropzone;