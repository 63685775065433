import React, { useState, useEffect, useCallback } from 'react';
import { 
  Sheet, Typography, Grid, Card, CardContent, Button, 
  Box, Modal, ModalDialog, ModalClose,
  FormControl, FormLabel, Input, Textarea, Select, Option,
  CircularProgress
} from '@mui/joy';
import { TestDefinition, TestType, ScoreDirection, DataType, UnitType } from '../types/interfaces';
import { getAllTestDefinitions, createTestDefinition, updateTestDefinition } from '../api';

const TestManagement: React.FC = () => {
  const [tests, setTests] = useState<TestDefinition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState<TestDefinition | null>(null);
  const [editedTest, setEditedTest] = useState<TestDefinition | null>(null);
  const [newTest, setNewTest] = useState<Partial<TestDefinition>>({
    name: '',
    shortName: '',
    description: '',
    units: undefined,
    precision: 2,
    type: undefined,
    scoreDirection: undefined,
    dataType: 'number',
    minValue: undefined,
    maxValue: undefined,
    defaultAttempts: 1
  });

  const fetchTests = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllTestDefinitions();
      setTests(response.data);
    } catch (err) {
      setError('Failed to fetch tests. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  const handleTestClick = (test: TestDefinition) => {
    setSelectedTest(test);
    setEditedTest(test);
  };

  const handleCreateTest = async () => {
    try {
      const response = await createTestDefinition(newTest);
      setTests(prevTests => [...prevTests, response.data]);
      setOpenModal(false);
      setNewTest({
        name: '',
        shortName: '',
        description: '',
        units: undefined,
        precision: 2,
        type: undefined,
        scoreDirection: undefined,
        dataType: 'number',
        minValue: undefined,
        maxValue: undefined,
        defaultAttempts: 1
      });
    } catch (err) {
      console.error('Failed to create test:', err);
      setError('Failed to create test. Please try again.');
    }
  };

  const handleUpdateTest = async () => {
    if (!editedTest) return;
    try {
      const response = await updateTestDefinition(editedTest.id, editedTest);
      setTests(prevTests => prevTests.map(t => t.id === editedTest.id ? response.data : t));
      setSelectedTest(null);
      setEditedTest(null);
    } catch (err) {
      console.error('Failed to update test:', err);
      setError('Failed to update test. Please try again.');
    }
  };

  const renderTestCard = (test: TestDefinition) => (
    <Card 
      key={test.id} 
      variant="outlined" 
      sx={{ cursor: 'pointer' }}
      onClick={() => handleTestClick(test)}
    >
      <CardContent>
        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
          {test.name}
        </Typography>
        <Typography level="body-sm">{test.shortName}</Typography>
        <Typography level="body-sm">{test.type}</Typography>
        <Typography level="body-sm">{test.units}</Typography>
      </CardContent>
    </Card>
  );

  const isTestChanged = () => {
    return JSON.stringify(selectedTest) !== JSON.stringify(editedTest);
  };

  if (isLoading) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold' }}>Tests</Typography>
        <Button onClick={() => setOpenModal(true)}>Create New Test</Button>
      </Box>
      
      {error && (
        <Typography color="danger" sx={{ mb: 2 }}>{error}</Typography>
      )}

      <Grid container spacing={2}>
        {tests.map(test => (
          <Grid key={test.id} xs={12} sm={6} md={4} lg={3}>
            {renderTestCard(test)}
          </Grid>
        ))}
      </Grid>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog sx={{ maxWidth: 600, width: '100%' }}>
          <ModalClose />
          <Typography level="h2" sx={{ mb: 2 }}>Create New Test</Typography>
          <form onSubmit={(e) => { e.preventDefault(); handleCreateTest(); }}>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input 
                    value={newTest.name || ''} 
                    onChange={(e) => setNewTest({...newTest, name: e.target.value})}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Short Name</FormLabel>
                  <Input 
                    value={newTest.shortName || ''} 
                    onChange={(e) => setNewTest({...newTest, shortName: e.target.value})}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <Select 
                    value={newTest.type || ''}
                    onChange={(_, value) => setNewTest({...newTest, type: value as TestType})}
                    required
                  >
                    <Option value={TestType.Gym}>Gym</Option>
                    <Option value={TestType.OnIce}>On-Ice</Option>
                    <Option value={TestType.Other}>Other</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Units</FormLabel>
                  <Select 
                    value={newTest.units || ''}
                    onChange={(_, value) => setNewTest({...newTest, units: value as UnitType})}
                    required
                  >
                    <Option value={UnitType.Reps}>Reps</Option>
                    <Option value={UnitType.Seconds}>Seconds</Option>
                    <Option value={UnitType.Inches}>Inches</Option>
                    <Option value={UnitType.Lbs}>Lbs</Option>
                    <Option value={UnitType.Mph}>Mph</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Precision</FormLabel>
                  <Input 
                    type="number"
                    value={newTest.precision ?? ''} 
                    onChange={(e) => setNewTest({...newTest, precision: e.target.value ? parseInt(e.target.value) : undefined})}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Score Direction</FormLabel>
                  <Select 
                    value={newTest.scoreDirection || ''}
                    onChange={(_, value) => setNewTest({...newTest, scoreDirection: value as ScoreDirection})}
                    required
                  >
                    <Option value={ScoreDirection.Ascending}>Ascending</Option>
                    <Option value={ScoreDirection.Descending}>Descending</Option>
                    <Option value={ScoreDirection.Neutral}>Neutral</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Data Type</FormLabel>
                  <Select 
                    value={newTest.dataType || ''}
                    onChange={(_, value) => setNewTest({...newTest, dataType: value as DataType})}
                    required
                  >
                    <Option value="number">Number</Option>
                    <Option value="string">String</Option>
                    <Option value="boolean">Boolean</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Min Value</FormLabel>
                  <Input 
                    type="number"
                    value={newTest.minValue ?? ''}
                    onChange={(e) => setNewTest({...newTest, minValue: e.target.value ? parseFloat(e.target.value) : undefined})}
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Max Value</FormLabel>
                  <Input 
                    type="number"
                    value={newTest.maxValue ?? ''}
                    onChange={(e) => setNewTest({...newTest, maxValue: e.target.value ? parseFloat(e.target.value) : undefined})}
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Default Attempts</FormLabel>
                  <Input 
                    type="number"
                    value={newTest.defaultAttempts || 1} 
                    onChange={(e) => setNewTest({...newTest, defaultAttempts: parseInt(e.target.value) || 1})}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea 
                    value={newTest.description || ''} 
                    onChange={(e) => setNewTest({...newTest, description: e.target.value})}
                    minRows={3}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <Button type="submit" sx={{ mt: 2 }}>Create Test</Button>
              </Grid>
            </Grid>
          </form>
        </ModalDialog>
      </Modal>

      {selectedTest && (
        <Modal open={true} onClose={() => { setSelectedTest(null); setEditedTest(null); }}>
          <ModalDialog sx={{ maxWidth: 600, width: '100%' }}>
            <ModalClose />
            <Typography level="h2" sx={{ mb: 2 }}>{selectedTest.name}</Typography>
            <Grid container spacing={2}>
              {Object.entries(editedTest || {}).map(([key, value]) => (
                <Grid xs={6} key={key}>
                  <FormControl>
                    <FormLabel>{key}</FormLabel>
                    {key === 'type' ? (
                      <Select
                        value={value as TestType}
                        onChange={(_, newValue) => setEditedTest(prev => prev ? {...prev, [key]: newValue as TestType} : null)}
                      >
                        {Object.values(TestType).map(type => (
                          <Option key={type} value={type}>{type}</Option>
                        ))}
                      </Select>
                    ) : key === 'units' ? (
                      <Select
                        value={value as UnitType}
                        onChange={(_, newValue) => setEditedTest(prev => prev ? {...prev, [key]: newValue as UnitType} : null)}
                      >
                        {Object.values(UnitType).map(unit => (
                          <Option key={unit} value={unit}>{unit}</Option>
                        ))}
                      </Select>
                    ) : key === 'scoreDirection' ? (
                      <Select
                        value={value as ScoreDirection}
                        onChange={(_, newValue) => setEditedTest(prev => prev ? {...prev, [key]: newValue as ScoreDirection} : null)}
                      >
                        {Object.values(ScoreDirection).map(direction => (
                          <Option key={direction} value={direction}>{direction}</Option>
                        ))}
                      </Select>
                    ) : key === 'dataType' ? (
                      <Select
                        value={value as DataType}
                        onChange={(_, newValue) => setEditedTest(prev => prev ? {...prev, [key]: newValue as DataType} : null)}
                      >
                        {['number', 'string', 'boolean'].map(type => (
                          <Option key={type} value={type}>{type}</Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={value as string}
                        onChange={(e) => setEditedTest(prev => prev ? {...prev, [key]: e.target.value} : null)}
                      />
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button onClick={handleUpdateTest} disabled={!isTestChanged()}>Save Changes</Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}
    </Sheet>
  );
};

export default TestManagement;