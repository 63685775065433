import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, LinearProgress, Typography, CircularProgress, Sheet, IconButton } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import MeasurementCard from '../components/MeasurementCard';
import { TestResult, Player, TestDefinition } from '../types/interfaces';
import { getAllTestResults, createTestResult, updateTestResult } from '../api';

interface SessionMeasurementProps {
  usesSessions: boolean;
}

const SessionMeasurement: React.FC<SessionMeasurementProps> = ({ usesSessions }) => {
  const { combineId, sessionId } = useParams<{ combineId: string; sessionId?: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTests, setSelectedTests] = useState<TestDefinition[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [currentTestIndex, setCurrentTestIndex] = useState(0);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [testResults, setTestResults] = useState<TestResult[]>([]);
  const [currentValue, setCurrentValue] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadSessionData = async () => {
      const state = location.state as { players?: Player[], tests?: TestDefinition[] } | null;

      if (state && Array.isArray(state.players) && Array.isArray(state.tests)) {
        if (state.players.length > 0 && state.tests.length > 0) {
          setSelectedTests(state.tests);
          setSelectedPlayers(state.players);
          
          try {
            const response = await getAllTestResults(combineId, usesSessions ? sessionId : undefined);
            setTestResults(response.data);
          } catch (error) {
            console.error('Failed to fetch existing test results:', error);
            setError('Failed to load existing test results. Please try again.');
          }
          
          setIsLoading(false);
          return true;
        }
      }

      return false;
    };

    if (!loadSessionData()) {
      setError('Failed to load session data. Please try restarting the session.');
      setIsLoading(false);
    }
  }, [location.state, combineId, sessionId, usesSessions]);

  useEffect(() => {
    if (selectedTests.length > 0 && selectedPlayers.length > 0) {
      const existingResult = testResults.find(
        result => result.playerId === selectedPlayers[currentPlayerIndex].id && 
                  result.testId === selectedTests[currentTestIndex].id
      );
      if (existingResult) {
        setCurrentValue(existingResult.value.toString());
      } else {
        setCurrentValue('');
      }
    }
  }, [currentTestIndex, currentPlayerIndex, testResults, selectedPlayers, selectedTests]);

  const handleNumberInput = (value: string) => {
    setCurrentValue(value);
  };

  const handleSave = async () => {
    const currentTest = selectedTests[currentTestIndex];
    const currentPlayer = selectedPlayers[currentPlayerIndex];

    const newResult: Partial<TestResult> = {
      combineId: combineId || '',
      sessionId: usesSessions ? sessionId : undefined,
      playerId: currentPlayer.id,
      testId: currentTest.id,
      value: parseFloat(currentValue),
      timestamp: new Date().toISOString(),
    };

    try {
      let apiResult: { data: TestResult };
      const existingResult = testResults.find(r => r.playerId === currentPlayer.id && r.testId === currentTest.id);
      
      if (existingResult) {
        apiResult = await updateTestResult(existingResult.id, newResult);
      } else {
        apiResult = await createTestResult(newResult);
      }

      setTestResults(prev => {
        const index = prev.findIndex(r => r.playerId === currentPlayer.id && r.testId === currentTest.id);
        if (index !== -1) {
          const newResults = [...prev];
          newResults[index] = apiResult.data;
          return newResults;
        }
        return [...prev, apiResult.data];
      });

      if (currentPlayerIndex === selectedPlayers.length - 1 && currentTestIndex === selectedTests.length - 1) {
        navigate(`/combines/${combineId}`, { state: { activeTab: 'results' } });
      } else {
        moveNext();
      }
    } catch (error) {
      console.error('Failed to save test result:', error);
      setError('Failed to save test result. Please try again.');
    }
  };

  const moveNext = () => {
    if (currentPlayerIndex < selectedPlayers.length - 1) {
      setCurrentPlayerIndex(prev => prev + 1);
    } else if (currentTestIndex < selectedTests.length - 1) {
      setCurrentTestIndex(prev => prev + 1);
      setCurrentPlayerIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentPlayerIndex > 0) {
      setCurrentPlayerIndex(prev => prev - 1);
    } else if (currentTestIndex > 0) {
      setCurrentTestIndex(prev => prev - 1);
      setCurrentPlayerIndex(selectedPlayers.length - 1);
    }
  };

  const handleBack = () => {
    navigate(`/combines/${combineId}`);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box>
        <Typography color="danger">{error}</Typography>
        <Button onClick={() => navigate(`/combines/${combineId}`)}>
          Return to Combine Details
        </Button>
      </Box>
    );
  }

  if (selectedTests.length === 0 || selectedPlayers.length === 0) {
    return (
      <Box>
        <Typography>No tests or players loaded for this session.</Typography>
        <Button onClick={() => navigate(`/combines/${combineId}`)}>
          Return to Combine Details
        </Button>
      </Box>
    );
  }

  const currentTest = selectedTests[currentTestIndex];
  const currentPlayer = selectedPlayers[currentPlayerIndex];

  const completedMeasurements = currentTestIndex * selectedPlayers.length + currentPlayerIndex;
  const totalMeasurements = selectedTests.length * selectedPlayers.length;
  const progress = (completedMeasurements / totalMeasurements) * 100;

  return (
    <Box sx={{ 
      height: '100%', 
      minHeight: '100vh',
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      p: 2 
    }}>
      <Sheet sx={{ 
        maxWidth: 600, 
        width: '100%', 
        height: 'auto', 
        maxHeight: 'calc(100vh - 32px)',
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        p: 2,
      }}>
        <IconButton
          onClick={handleBack}
          size="sm"
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>
        <LinearProgress
          determinate
          value={progress}
          sx={{ mb: 1 }}
        />
        <Typography level="h2" sx={{ mb: 1 }}>
          {currentTest.name}
        </Typography>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <MeasurementCard
            currentTest={currentTest}
            currentPlayer={currentPlayer}
            currentValue={currentValue}
            testResult={testResults.find(r => r.playerId === currentPlayer.id && r.testId === currentTest.id) || null}
            onNumberInput={handleNumberInput}
            onSave={handleSave}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Button onClick={handlePrevious} disabled={currentTestIndex === 0 && currentPlayerIndex === 0}>
            Previous
          </Button>
          <Button onClick={handleSave} disabled={currentValue === ''}>
            Save
          </Button>
          <Button onClick={moveNext} disabled={currentTestIndex === selectedTests.length - 1 && currentPlayerIndex === selectedPlayers.length - 1}>
            Next
          </Button>
        </Box>
      </Sheet>
    </Box>
  );
};

export default SessionMeasurement;