// src/components/CombineReport.tsx

import React, { useState, useMemo } from 'react';
import { Sheet, Typography, List, ListItem, ListItemButton, Modal, ModalDialog, ModalClose, IconButton } from '@mui/joy';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Player, TestResult, AdvancedResult } from '../types/interfaces';
import PlayerReports from './PlayerReports';
import { useCombineDetails } from '../contexts/CombineDetailsContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface PlayerSummary {
  player: Player;
  overallRank: number;
  onIceRank: number;
  gymRank: number;
  overallScore: number;
  onIceScore: number;
  gymScore: number;
}

const CombineReport: React.FC = () => {
  const { combine, players, selectedTests, testResults } = useCombineDetails();
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const calculateZScore = (
    value: number, 
    mean: number, 
    stdDev: number, 
    scoreDirection: 'Ascending' | 'Descending' | 'Neutral'
  ) => {
    const rawZScore = stdDev === 0 ? 0 : (value - mean) / stdDev;
    switch (scoreDirection) {
      case 'Ascending':
        return rawZScore;
      case 'Descending':
        return -rawZScore;
      case 'Neutral':
      default:
        return Math.abs(rawZScore);  // For neutral, we consider distance from mean
    }
  };

  const advancedResults = useMemo(() => {
    const calculateStatistics = (results: TestResult[]) => {
      if (results.length === 0) return { mean: 0, stdDev: 0 };
      const values = results.map(r => Number(r.value));
      const mean = values.reduce((sum, val) => sum + val, 0) / values.length;
      const variance = values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length;
      const stdDev = Math.sqrt(variance);
      return { mean, stdDev };
    };
  
    const advancedResults: AdvancedResult[] = [];
  
    selectedTests.forEach(test => {
      const filteredResults = testResults.filter((r: TestResult) => r.testId === test.id);
      const { mean, stdDev } = calculateStatistics(filteredResults);
  
      const sortedResults = [...filteredResults].sort((a, b) => {
        const aValue = Number(a.value);
        const bValue = Number(b.value);
        switch (test.scoreDirection) {
          case 'Ascending':
            return bValue - aValue;
          case 'Descending':
            return aValue - bValue;
          case 'Neutral':
          default:
            return Math.abs(aValue - mean) - Math.abs(bValue - mean);
        }
      });
  
      sortedResults.forEach((result, index) => {
        advancedResults.push({
          ...result,
          rank: index + 1,
          zScore: calculateZScore(Number(result.value), mean, stdDev, test.scoreDirection),
        });
      });
    });
  
    return advancedResults;
  }, [testResults, selectedTests]);

  const playerSummaries = useMemo(() => {
    const summaries: PlayerSummary[] = players.map(player => {
      const playerResults = advancedResults.filter(r => r.playerId === player.id);
      const overallScore = playerResults.reduce((sum, r) => sum + r.zScore, 0) / (playerResults.length || 1);
      const onIceResults = playerResults.filter(r => selectedTests.find(t => t.id === r.testId)?.type === 'On-Ice');
      const onIceScore = onIceResults.reduce((sum, r) => sum + r.zScore, 0) / (onIceResults.length || 1);
      const gymResults = playerResults.filter(r => selectedTests.find(t => t.id === r.testId)?.type === 'Gym');
      const gymScore = gymResults.reduce((sum, r) => sum + r.zScore, 0) / (gymResults.length || 1);
  
      return {
        player,
        overallScore,
        onIceScore,
        gymScore,
        overallRank: 0,
        onIceRank: 0,
        gymRank: 0,
      };
    });

    const rankSummaries = (summaries: PlayerSummary[], scoreKey: 'overallScore' | 'onIceScore' | 'gymScore', rankKey: 'overallRank' | 'onIceRank' | 'gymRank') => {
      return summaries.sort((a, b) => b[scoreKey] - a[scoreKey]).map((summary, index) => ({
        ...summary,
        [rankKey]: index + 1,
      }));
    };

    return rankSummaries(
      rankSummaries(
        rankSummaries(summaries, 'overallScore', 'overallRank'),
        'onIceScore',
        'onIceRank'
      ),
      'gymScore',
      'gymRank'
    );
  }, [advancedResults, players, selectedTests]);

  const createBarChart = (data: PlayerSummary[], scoreKey: 'overallScore' | 'onIceScore' | 'gymScore', title: string) => {
    const sortedData = [...data].sort((a, b) => b[scoreKey] - a[scoreKey]);
    const chartData = {
      labels: sortedData.map(s => `${s.player.firstName} ${s.player.lastName}`),
      datasets: [
        {
          label: title,
          data: sortedData.map(s => s[scoreKey]),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: title,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  const handlePlayerSelect = (player: Player) => {
    setSelectedPlayer(player);
    setIsModalOpen(true);
  };

  const generateCsv = (): string => {
    const testColumns = selectedTests.flatMap(test => [
      `${test.name} Value`,
      `${test.name} Rank`,
      `${test.name} Z-Score`
    ]);
  
    const headers = [
      'Player',
      'Overall Rank',
      'On-Ice Rank',
      'Gym Rank',
      'Overall Score',
      'On-Ice Score',
      'Gym Score',
      ...testColumns
    ];
  
    const rows = playerSummaries.map(summary => {
      const playerResults = advancedResults.filter(r => r.playerId === summary.player.id);
      const baseData = [
        `"${summary.player.firstName} ${summary.player.lastName}"`,
        summary.overallRank,
        summary.onIceRank,
        summary.gymRank,
        summary.overallScore.toFixed(2),
        summary.onIceScore.toFixed(2),
        summary.gymScore.toFixed(2),
      ];
  
      const testData = selectedTests.flatMap(test => {
        const result = playerResults.find(r => r.testId === test.id);
        if (result) {
          return [
            result.value,
            result.rank,
            result.zScore.toFixed(2)
          ];
        } else {
          return ['N/A', 'N/A', 'N/A'];
        }
      });
  
      return [...baseData, ...testData];
    });
  
    return [headers, ...rows].map(row => row.join(',')).join('\n');
  };

  const downloadCsv = () => {
    const csvContent = generateCsv();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `combine_report_${combine?.name || 'unnamed'}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Sheet 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 4
        }}
      >
        <Typography level="h1">Combine Report</Typography>
        <IconButton onClick={downloadCsv} size="lg">
          <DownloadRoundedIcon />
        </IconButton>
      </Sheet>
      
      <Sheet variant="soft" sx={{ p: 2, mb: 4 }}>
        {createBarChart(playerSummaries, 'overallScore', 'Overall Performance')}
      </Sheet>

      <Sheet variant="soft" sx={{ p: 2, mb: 4 }}>
        {createBarChart(playerSummaries, 'onIceScore', 'On-Ice Performance')}
      </Sheet>

      <Sheet variant="soft" sx={{ p: 2, mb: 4 }}>
        {createBarChart(playerSummaries, 'gymScore', 'Gym Performance')}
      </Sheet>

      <Typography level="h2" sx={{ mt: 4, mb: 2 }}>Player Reports</Typography>
      <List>
        {playerSummaries.map(summary => (
          <ListItem key={summary.player.id}>
            <ListItemButton onClick={() => handlePlayerSelect(summary.player)}>
              {summary.player.firstName} {summary.player.lastName} - Rank: {summary.overallRank}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalDialog sx={{ maxWidth: 800, width: '100%' }}>
          <ModalClose />
          {selectedPlayer && (
            <PlayerReports
              player={selectedPlayer}
              advancedResults={advancedResults}
              tests={selectedTests}
              totalPlayers={players.length}
            />
          )}
        </ModalDialog>
      </Modal>

      {/* Render all player reports off-screen for PDF export */}
      <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
        {playerSummaries.map(summary => (
          <PlayerReports
            key={summary.player.id}
            player={summary.player}
            advancedResults={advancedResults}
            tests={selectedTests}
            totalPlayers={players.length}
          />
        ))}
      </div>
    </Sheet>
  );
};

export default CombineReport;