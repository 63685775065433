// src/components/CombineRoster.tsx

import React, { useState } from 'react';
import { Sheet, Typography, Button, CircularProgress, Table } from '@mui/joy';
import { Player } from '../types/interfaces';
import { addPlayersToCombine, createPlayer } from '../api';
import AddPlayerModal from './AddPlayerModal';
import PlayerImportValidator from './PlayerImportValidator';
import { useCombineDetails } from '../contexts/CombineDetailsContext';

const CombineRoster: React.FC = () => {
  const { combine, players, isLoading, error, updateRoster, isEditingAllowed } = useCombineDetails();
  const [isAddPlayerModalOpen, setIsAddPlayerModalOpen] = useState(false);

  const handleAddPlayer = async (playerData: Partial<Player>) => {
    if (!combine) return;

    try {
      const createdPlayerResponse = await createPlayer(playerData);
      const createdPlayer = createdPlayerResponse.data;
      await addPlayersToCombine(combine.id, { players: [createdPlayer.id] });
      const updatedPlayers = [...players, createdPlayer];
      updateRoster(updatedPlayers);
    } catch (err) {
      console.error('Failed to add player:', err);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handlePlayersValidated = async (validatedPlayers: Player[]) => {
    if (!combine) return;

    try {
      const playerIds = validatedPlayers.map(player => player.id);
      await addPlayersToCombine(combine.id, { players: playerIds });
      const updatedPlayers = [...players, ...validatedPlayers];
      updateRoster(updatedPlayers);
    } catch (err) {
      console.error('Failed to add players to combine:', err);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (isLoading) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 2, display: 'flex', height: '100%' }}>
      <Sheet sx={{ flex: 2, p: 2, overflow: 'auto' }}>
        <Typography level="h2" sx={{ mb: 2 }}>Combine Roster</Typography>
        {error && (
          <Typography color="danger" sx={{ mb: 2 }}>{error}</Typography>
        )}
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player) => (
              <tr key={player.id}>
                <td>{`${player.firstName} ${player.lastName}`}</td>
                <td>{player.position || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Sheet sx={{ flex: 1, p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button 
          onClick={() => setIsAddPlayerModalOpen(true)} 
          disabled={!isEditingAllowed(combine?.status || '')}
        >
          Add Player
        </Button>
        <PlayerImportValidator
          onPlayersAdded={handlePlayersValidated}
          combineId={combine?.id || ''}
          isEditingAllowed={isEditingAllowed(combine?.status || '')}
        />
      </Sheet>

      <AddPlayerModal
        open={isAddPlayerModalOpen}
        onClose={() => setIsAddPlayerModalOpen(false)}
        onAddPlayer={handleAddPlayer}
      />
    </Sheet>
  );
};

export default CombineRoster;