import React, { useState, useMemo } from 'react';
import {
  Table,
  Sheet,
  Typography,
  Button,
  ButtonGroup,
  Box,
  Tooltip,
  useColorScheme
} from '@mui/joy';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import { useCombineDetails } from '../contexts/CombineDetailsContext';
import ResultsImportModal from './ResultsImportModal';
import { TestResult, TestDefinition } from '../types/interfaces';

const CombineResults: React.FC = () => {
  const {
    combine,
    players,
    selectedTests,
    testResults,
    usesSessions,
    isEditingAllowed,
    updateTestResults
  } = useCombineDetails();

  const [viewMode, setViewMode] = useState<'view' | 'edit' | 'select'>('view');
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set());
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const navigate = useNavigate();
  const { mode } = useColorScheme();

  const toggleCell = (playerId: string, testId: string) => {
    const cellKey = `${playerId},${testId}`;
    setSelectedCells(prev => {
      const newSet = new Set(prev);
      if (newSet.has(cellKey)) {
        newSet.delete(cellKey);
      } else {
        newSet.add(cellKey);
      }
      return newSet;
    });
  };

  const toggleAllPlayersForTest = (testId: string) => {
    const newSelectedCells = new Set(selectedCells);
    const isAllSelected = players.every(player => newSelectedCells.has(`${player.id},${testId}`));

    players.forEach(player => {
      const cellKey = `${player.id},${testId}`;
      if (isAllSelected) {
        newSelectedCells.delete(cellKey);
      } else {
        newSelectedCells.add(cellKey);
      }
    });

    setSelectedCells(newSelectedCells);
  };

  const toggleAllTestsForPlayer = (playerId: string) => {
    const newSelectedCells = new Set(selectedCells);
    const isAllSelected = selectedTests.every(test => newSelectedCells.has(`${playerId},${test.id}`));

    selectedTests.forEach(test => {
      const cellKey = `${playerId},${test.id}`;
      if (isAllSelected) {
        newSelectedCells.delete(cellKey);
      } else {
        newSelectedCells.add(cellKey);
      }
    });

    setSelectedCells(newSelectedCells);
  };

  const handleLaunchSession = async (selectedPlayerIds: Set<string>, selectedTestIds: Set<string>) => {
    if (selectedPlayerIds.size === 0 || selectedTestIds.size === 0) {
      alert('Please select at least one player and one test');
      return;
    }

    if (usesSessions) {
      // Navigate to session creation page
      navigate(`/combines/${combine?.id}/create-session`, {
        state: {
          selectedPlayerIds: Array.from(selectedPlayerIds),
          selectedTestIds: Array.from(selectedTestIds)
        }
      });
    } else {
      navigate(`/combines/${combine?.id}/measure`, {
        state: {
          players: players.filter(player => selectedPlayerIds.has(player.id)),
          tests: selectedTests.filter(test => selectedTestIds.has(test.id))
        }
      });
    }
  };

  const sortedTests = useMemo(() => {
    return [...selectedTests].sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === 'On-Ice' ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });
  }, [selectedTests]);

  const formatColumnHeader = (test: TestDefinition) => {
    const words = test.name.split(' ');
    if (words.length <= 2) {
      return words.join('\n');
    }
    const compactName = words.join('');
    if (compactName.length <= 10) {
      return compactName;
    }
    return test.shortName;
  };

  const handleCellClick = (playerId: string, testId: string) => {
    if (viewMode === 'select') {
      toggleCell(playerId, testId);
    } else if (viewMode === 'edit') {
      handleLaunchSession(new Set([playerId]), new Set([testId]));
    }
  };

  const handleStartTesting = () => {
    const selectedPlayerIds = new Set<string>();
    const selectedTestIds = new Set<string>();

    selectedCells.forEach(cell => {
      const [playerId, testId] = cell.split(',');
      selectedPlayerIds.add(playerId);
      selectedTestIds.add(testId);
    });

    handleLaunchSession(selectedPlayerIds, selectedTestIds);
  };

  const handleSort = (column: string) => {
    if (viewMode !== 'view') return;
    
    if (sortColumn === column) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedPlayers = useMemo(() => {
    if (!sortColumn) return players;
  
    return [...players].sort((a, b) => {
      if (sortColumn === 'name') {
        const nameA = `${a.lastName}, ${a.firstName}`;
        const nameB = `${b.lastName}, ${b.firstName}`;
        return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else {
        const resultA = testResults.find(r => r.playerId === a.id && r.testId === sortColumn);
        const resultB = testResults.find(r => r.playerId === b.id && r.testId === sortColumn);
        const valueA = resultA ? resultA.value : -Infinity;
        const valueB = resultB ? resultB.value : -Infinity;
        return sortDirection === 'asc' ? 
          (valueA as number) - (valueB as number) : 
          (valueB as number) - (valueA as number);
      }
    });
  }, [players, testResults, sortColumn, sortDirection]);

  const handleImportComplete = (importedResults: Partial<TestResult>[]) => {
    updateTestResults(importedResults as TestResult[]);
  };

  const hasSelectedCells = selectedCells.size > 0;

  return (
    <Sheet variant="outlined" sx={{ p: 2, overflow: 'auto' }}>
      <Typography level="h2" sx={{ mb: 2 }}>Combine Results</Typography>

      <Sheet
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Box>
          <ButtonGroup variant="outlined" aria-label="Mode selection">
            <Button
              onClick={() => setViewMode('view')}
              variant={viewMode === 'view' ? 'solid' : 'outlined'}
              startDecorator={<VisibilityRoundedIcon />}
            >
              View
            </Button>
            <Tooltip title={isEditingAllowed(combine?.status || '') ? '' : "Editing is not allowed for completed combines"}>
              <Button
                onClick={() => isEditingAllowed(combine?.status || '') && setViewMode('edit')}
                variant={viewMode === 'edit' ? 'solid' : 'outlined'}
                startDecorator={<EditRoundedIcon />}
                disabled={!isEditingAllowed(combine?.status || '')}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip title={isEditingAllowed(combine?.status || '') ? '' : "Selection is not allowed for completed combines"}>
              <Button
                onClick={() => isEditingAllowed(combine?.status || '') && setViewMode('select')}
                variant={viewMode === 'select' ? 'solid' : 'outlined'}
                startDecorator={<CheckBoxOutlineBlankIcon />}
                disabled={!isEditingAllowed(combine?.status || '')}
              >
                Select
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>

        <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <Button
            onClick={() => setIsImportModalOpen(true)}
            disabled={!isEditingAllowed(combine?.status || '')}
          >
            Import Results
          </Button>
        </Box>

        {viewMode === 'select' && (
          <Button
            onClick={handleStartTesting}
            disabled={!hasSelectedCells}
          >
            Start Testing
          </Button>
        )}
      </Sheet>

      <Table
        stickyHeader
        sx={{
          '& th, & td': {
            height: 'auto',
            padding: '8px 4px',
            verticalAlign: 'center',
            borderBottom: '1px solid',
            borderRight: '1px solid',
            borderColor: mode === 'light' ? 'neutral.200' : 'neutral.700',
          },
          '& th': {
            borderTop: '1px solid',
            borderColor: mode === 'light' ? 'neutral.200' : 'neutral.700',
          },
          '& th:first-of-type, & td:first-of-type': {
            borderLeft: '1px solid',
            borderColor: mode === 'light' ? 'neutral.200' : 'neutral.700',
          },
          '& th > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
          },
          '& th:not(:first-of-type) > div, & td:not(:first-of-type)': {
            textAlign: 'right',
          },
          '& th:first-of-type > div, & td:first-of-type': {
            textAlign: 'left',
          }
        }}
      >
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: viewMode === 'view' ? 'pointer' : 'default' }}>
              <div>
                Player
                {sortColumn === 'name' && (
                  sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                )}
              </div>
            </th>
            {sortedTests.map(test => (
              <th
                key={test.id}
                onClick={() => viewMode === 'view' ? handleSort(test.id) : viewMode === 'select' && toggleAllPlayersForTest(test.id)}
                style={{ cursor: viewMode !== 'edit' ? 'pointer' : 'default' }}
              >
                <div>
                  {formatColumnHeader(test)}
                  {sortColumn === test.id && (
                    sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map(player => (
            <tr key={player.id}>
              <td
                onClick={() => viewMode === 'select' && toggleAllTestsForPlayer(player.id)}
                style={{ cursor: viewMode === 'select' ? 'pointer' : 'default' }}
              >
                {`${player.lastName}, ${player.firstName}`}
              </td>
              {sortedTests.map(test => {
                const result = testResults.find(r => r.playerId === player.id && r.testId === test.id);
                const cellKey = `${player.id},${test.id}`;
                return (
                  <td
                    key={cellKey}
                    onClick={() => handleCellClick(player.id, test.id)}
                    style={{
                      cursor: viewMode !== 'view' ? 'pointer' : 'default',
                      backgroundColor: selectedCells.has(cellKey)
                        ? (mode === 'light' ? 'rgb(200, 200, 200)' : 'rgb(80, 80, 80)')
                        : 'inherit',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {result ? result.value : ''}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>

      <ResultsImportModal
        open={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        combineId={combine?.id || ''}
        players={players}
        onImportComplete={handleImportComplete}
      />
    </Sheet>
  );
};

export default CombineResults;