import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/joy';
import { getAllOrganizations } from '../api';
import { Organization } from '../types/interfaces';

const Organizations: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await getAllOrganizations();
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizations();
  }, []);

  return (
    <Box>
      <Typography level="h2" component="h1" mb={2}>
        Organizations
      </Typography>
      <Grid container spacing={2}>
        {organizations.map((org) => (
          <Grid key={org.id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column',
                gap: 2,
              }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '120px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {org.logoUri && (
                    <img
                      src={org.logoUri}
                      alt={`${org.name} logo`}
                      style={{
                        maxWidth: '80%',
                        maxHeight: '80%',
                        objectFit: 'contain',
                        objectPosition: 'center',
                      }}
                    />
                  )}
                </Box>
                <Box>
                  <Typography level="h4" component="h2">
                    {org.name}
                  </Typography>
                  {org.longName && (
                    <Typography level="body-sm">
                      {org.longName}
                    </Typography>
                  )}
                </Box>
                {org.description && (
                  <Typography level="body-md">
                    {org.description}
                  </Typography>
                )}
                <Box sx={{ mt: 'auto' }}>
                  {org.websiteUrl && (
                    <Typography level="body-sm">
                      <a href={org.websiteUrl} target="_blank" rel="noopener noreferrer">{org.websiteUrl}</a>
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Organizations;