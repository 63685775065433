import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Sheet, Typography, Button, FormControl, FormLabel, Input, Select, Option 
} from '@mui/joy';
import { TestDefinition } from '../types/interfaces';
import { tests } from '../localData';

const TestDetails: React.FC = () => {
  const { testId } = useParams<{ testId: string }>();
  const navigate = useNavigate();
  const [test, setTest] = useState<TestDefinition | null>(null);

  useEffect(() => {
    const foundTest = tests.find(t => t.id === testId);
    if (foundTest) {
      setTest(foundTest);
    } else {
      navigate('/tests', { replace: true });
    }
  }, [testId, navigate]);

  if (!test) return null;

  const handleChange = (field: keyof TestDefinition, value: string | number) => {
    setTest(prev => prev ? {...prev, [field]: value} : null);
  };

  const handleSave = () => {
    // Here you would typically make an API call to update the test
    console.log('Save test:', test);
    navigate('/tests');
  };

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>
        Test Details: {test.name}
      </Typography>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Name</FormLabel>
          <Input 
            value={test.name} 
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Type</FormLabel>
          <Select 
            value={test.type}
            onChange={(_, value) => handleChange('type', value as 'Gym' | 'On-Ice' | 'Other')}
          >
            <Option value="Gym">Gym</Option>
            <Option value="On-Ice">On-Ice</Option>
            <Option value="Other">Other</Option>
          </Select>
        </FormControl>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Units</FormLabel>
          <Input 
            value={test.units} 
            onChange={(e) => handleChange('units', e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Description</FormLabel>
          <Input 
            value={test.description} 
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </FormControl>
        <Button type="submit" sx={{ mr: 1 }}>Save Changes</Button>
        <Button onClick={() => navigate('/tests')} color="neutral">Cancel</Button>
      </form>
    </Sheet>
  );
};

export default TestDetails;