import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator, View, Text } from '@aws-amplify/ui-react';
import { CssVarsProvider } from '@mui/joy';
import Layout from './components/Layout';
import Login from './pages/Login';
import CombinesList from './pages/CombinesList';
import CombineDetails from './pages/CombineDetails';
import PlayerRollCall from './pages/PlayerRollCall';
import TestSelection from './pages/TestSelection';
import SessionMeasurement from './pages/SessionMeasurement';
import SessionResults from './pages/SessionResults';
import TestManagement from './pages/TestManagement';
import TestDetails from './pages/TestDetails';
import Settings from './pages/Settings';
import Players from './pages/Players';
import ImportCombine from './pages/ImportCombine';
import Dashboard from './components/Dashboard';
import PlayerPage from './pages/PlayerPage';
import Organizations from './pages/Organizations';
import UserAgreementModal from './components/UserAgreementModal';
import { getCurrentUser } from './api';
import { User } from './types/interfaces';
import { EULA } from './assets/EULA';


const formFields = {
  signIn: {
    username: {
      label: 'Username',
      placeholder: 'Enter your username',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
  },
};

const components = {
  SignUp: {
    FormFields() {
      return (
        <View>
          <Text>Sign up is currently disabled. Please contact an administrator for access.</Text>
        </View>
      );
    },
  },
};

const PlayerPageWrapper = () => {
  const { playerId } = useParams<{ playerId: string }>();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/players');
  };

  if (!playerId) {
    return <Navigate to="/players" />;
  }

  return <PlayerPage playerId={playerId} onClose={handleClose} />;
};

function AuthenticatedApp() {
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
  const [showAgreement, setShowAgreement] = useState(false);
  const [demoMode, setDemoMode] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
        if (userResponse.data.isFirstTimeVisitor || userResponse.data.lastEULASigned !== EULA.version) {
          setShowAgreement(true);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        // Handle error (e.g., redirect to login)
      } 
    };

    if (user) {
      fetchUser();
    }
  }, [user]);

  const handleAgreement = () => {
    setShowAgreement(false);
    setCurrentUser(prev => prev ? { ...prev, isFirstTimeVisitor: false } : null);
  };

  const handleDecline = () => {
    signOut();
  };

  return (
    <Router>
      <Layout signOut={signOut}>
        {currentUser && (
          <UserAgreementModal
            isOpen={showAgreement}
            onClose={() => setShowAgreement(false)}
            user={currentUser}
            onAgreement={handleAgreement}
            onDecline={handleDecline}
          />
        )}
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login signOut={signOut} user={user} />} />
          <Route path="/combines" element={<CombinesList />} />
          <Route path="/import-combine" element={<ImportCombine />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/combines/:combineId" element={<CombineDetails />} />
          <Route path="/combines/:combineId/roll-call" element={<PlayerRollCall />} />
          <Route path="/combines/:combineId/test-selection" element={<TestSelection />} />
          <Route
            path="/combines/:combineId/sessions/:sessionId/roll-call"
            element={<SessionMeasurement usesSessions={true}/>}
          />
          <Route
            path="/combines/:combineId/sessions/:sessionId/test-selection"
            element={<SessionMeasurement usesSessions={true}/>}
          />
          <Route
            path="/combines/:combineId/sessions/:sessionId/measure"
            element={<SessionMeasurement usesSessions={true}/>}
          />
          <Route
            path="/combines/:combineId/sessions/:sessionId/results"
            element={<SessionResults />}
          />
          <Route
            path="/combines/:combineId/measure"
            element={<SessionMeasurement usesSessions={false} />}
          />
          <Route
            path="/combines/:combineId/sessions/:sessionId/measure"
            element={<SessionMeasurement usesSessions={true} />}
          />
          <Route path="/players" element={<Players />} />
          <Route path="/players/:playerId" element={<PlayerPageWrapper />} />
          <Route path="/tests" element={<TestManagement />} />
          <Route path="/tests/:testId" element={<TestDetails />} />
          <Route path="/organizations" element={<Organizations />} />
          <Route path="/settings" element={<Settings demoMode={demoMode} setDemoMode={setDemoMode} />} />
        </Routes>
      </Layout>
    </Router>
  );
}

function App() {
  return (
    <CssVarsProvider>
      <Authenticator
        formFields={formFields}
        components={components}
        hideSignUp={true}
        loginMechanism="username"
      >
        <AuthenticatedApp />
      </Authenticator>
    </CssVarsProvider>
  );
}

export default App;