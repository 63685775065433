// src/components/CombineSessions.tsx
import React, { useState, useEffect } from 'react';
import { Button, List, ListItem, Typography, Sheet, Modal, ModalDialog, Input, FormControl, FormLabel, Box, CircularProgress } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { CombineSession } from '../types/interfaces';
import { getAllCombineSessions, createCombineSession } from '../api';

interface CombineSessionsProps {
  combineId: string;
}

const CombineSessions: React.FC<CombineSessionsProps> = ({ combineId }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSessionName, setNewSessionName] = useState('');
  const [sessions, setSessions] = useState<CombineSession[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combineId]);

  const fetchSessions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllCombineSessions(combineId);
      setSessions(response.data);
    } catch (err) {
      setError('Failed to fetch sessions. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewSession = async () => {
    if (!newSessionName.trim()) {
      setError('Please enter a session name.');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    try {
      const response = await createCombineSession({
        combineId,
        name: newSessionName.trim(),
        date: new Date().toISOString().split('T')[0],
        status: 'not_started',
      });
      setSessions(prevSessions => [...prevSessions, response.data]);
      setNewSessionName('');
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to create new session. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewSession = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await createCombineSession({
        combineId,
        name: `Quick Session ${sessions.length + 1}`,
        date: new Date().toISOString().split('T')[0],
        status: 'not_started',
      });
      navigate(`/combines/${combineId}/sessions/${response.data.id}/measure`);
    } catch (err) {
      setError('Failed to start new session. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewSession = (sessionId: string) => {
    navigate(`/combines/${combineId}/sessions/${sessionId}/measure`);
  };

  if (isLoading) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  if (error) {
    return (
      <Sheet sx={{ p: 2 }}>
        <Typography color="danger">{error}</Typography>
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Combine Sessions</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button 
          onClick={handleStartNewSession}
          color="primary"
          variant="solid"
        >
          Start New Session
        </Button>
        <Button 
          onClick={() => setIsModalOpen(true)}
          color="primary"
          variant="outlined"
        >
          Add New Session
        </Button>
      </Box>
      {sessions.length > 0 ? (
        <List>
          {sessions.map((session) => (
            <ListItem key={session.id}>
              <Button
                variant="outlined"
                onClick={() => handleViewSession(session.id)}
                fullWidth
              >
                {session.name} - {new Date(session.date).toLocaleDateString()}
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No sessions have been created yet.</Typography>
      )}

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalDialog>
          <Typography level="h4" sx={{ mb: 2 }}>Add New Session</Typography>
          <FormControl>
            <FormLabel>Session Name</FormLabel>
            <Input
              value={newSessionName}
              onChange={(e) => setNewSessionName(e.target.value)}
              placeholder="Enter session name"
              required
            />
          </FormControl>
          <Button onClick={handleAddNewSession} sx={{ mt: 2 }}>
            Create Session
          </Button>
        </ModalDialog>
      </Modal>
    </Sheet>
  );
};

export default CombineSessions;