export const EULA = {
    version: "1.1",
    date: "2023-09-17",
    content: `
      <h1>Terms of Use for Measure Up Beta</h1>
  
      <h2>1. Acceptance of Terms</h2>
      <p>By accessing or using Measure Up (the "App"), a product of Flyr Drones LLC ("Company"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, do not use the App.</p>
  
      <h2>2. Description of Service</h2>
      <p>Measure Up is a beta version of a combine management application. As a beta product, the App may contain bugs, errors, or inaccuracies and may not function as intended.</p>
  
      <h2>3. Beta Program</h2>
      <ol>
          <li>You understand and acknowledge that the App is in a beta testing phase.</li>
          <li>You agree to use the App for testing and evaluation purposes only.</li>
          <li>You understand that your use of the App during the beta phase is at your own risk.</li>
      </ol>

      <h2>4. License Grant</h2>
      <p>Subject to these Terms, Flyr Drones LLC grants you a limited, non-exclusive, non-transferable, and revocable license to use the App for its intended purpose of testing and evaluating the combine management features. This license does not allow you to modify, distribute, or create derivative works of the App.</p>
  
      <h2>5. Intellectual Property Rights</h2>
      <ol>
          <li>All content, features, and functionality of the App, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, are the exclusive property of Flyr Drones LLC and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</li>
          <li>You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as expressly permitted by these Terms.</li>
          <li>You acknowledge that any feedback, suggestions, ideas, or other information you provide about the App ("Feedback") may be used by Flyr Drones LLC without any obligation to compensate you for them.</li>
      </ol>
  
      <h2>6. Confidentiality</h2>
      <ol>
          <li>You agree to keep confidential and not disclose to any third party any information about the App that is not publicly available, including but not limited to its features, functionality, performance, and any bugs or errors you encounter.</li>
          <li>You agree not to use any confidential information for any purpose other than testing and evaluating the App.</li>
      </ol>
  
      <h2>7. Prohibited Uses</h2>
      <p>You agree not to use the App:</p>
      <ol>
          <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate Flyr Drones LLC, its employees, another user, or any other person or entity.</li>
          <li>To reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the App.</li>
      </ol>

      <h2>8. Data Collection and Usage</h2>
      <ol>
          <li>Flyr Drones LLC collects and processes certain data, including personal information, to provide and improve the App. This may include, but is not limited to, usage data, device information, and any information you voluntarily provide while using the App.</li>
          <li>We use this data to improve the App, troubleshoot issues, and provide customer support. We do not sell your personal information to third parties.</li>
          <li>For more detailed information about our data practices, please refer to our Privacy Policy.</li>
      </ol>
  
      <h2>9. Disclaimer of Warranties</h2>
      <ol>
          <li>The App is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</li>
          <li>Flyr Drones LLC disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising out of course of dealing or usage of trade.</li>
      </ol>
  
      <h2>10. Limitation of Liability</h2>
      <p>In no event shall Flyr Drones LLC be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the App.</p>
  
      <h2>11. Indemnification</h2>
      <p>You agree to defend, indemnify, and hold harmless Flyr Drones LLC, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the App.</p>
  
      <h2>12. Changes to Terms</h2>
      <p>Flyr Drones LLC reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
  
      <h2>13. Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of the Commonwealth of Pennsylvania, United States of America, without regard to its conflict of law provisions.</p>
  
      <h2>14. Contact Information</h2>
      <p>Questions about the Terms should be sent to us at support@flyrdrones.com.</p>
  
      <p>By using Measure Up, you acknowledge that you have read these Terms of Use, understood them, and agree to be bound by them.</p>
    `
  };