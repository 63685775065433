// src/pages/Players.tsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Sheet,
  Typography,
  Input,
  Button,
  Table,
  IconButton,
  Select,
  Option,
  CircularProgress,
  Drawer
} from '@mui/joy';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Player } from '../types/interfaces';
import { getAllPlayers, createPlayer } from '../api';
import AddPlayerModal from '../components/AddPlayerModal';
import PlayerPage from './PlayerPage';

const Players: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof Player>('lastName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    position: '',
    birthYear: ''
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);
  const [isPlayerDetailsOpen, setIsPlayerDetailsOpen] = useState(false);

  const fetchPlayers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getAllPlayers();
      setPlayers(response.data);
    } catch (err) {
      setError('Failed to fetch players. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPlayers();
  }, [fetchPlayers]);

  const handlePlayerClick = useCallback((playerId: string) => {
    setSelectedPlayerId(playerId);
    setIsPlayerDetailsOpen(true);
    window.history.pushState(null, '', `/players/${playerId}`);
  }, []);

  const handleClosePlayerDetails = useCallback(() => {
    setIsPlayerDetailsOpen(false);
    setSelectedPlayerId(null);
    window.history.pushState(null, '', '/players');
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const pathParts = window.location.pathname.split('/');
      const playerId = pathParts[pathParts.length - 1];
      if (playerId !== 'players') {
        setSelectedPlayerId(playerId);
        setIsPlayerDetailsOpen(true);
      } else {
        handleClosePlayerDetails();
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [handleClosePlayerDetails]);

  const handleSort = (column: keyof Player) => {
    if (sortColumn === column) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleAddPlayer = async (newPlayer: Partial<Player>) => {
    try {
      const response = await createPlayer(newPlayer);
      setPlayers(prev => [...prev, response.data]);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Failed to add player:', error);
      setError('Failed to add player. Please try again.');
    }
  };

  const filteredAndSortedPlayers = useMemo(() => {
    return players
      .filter(player =>
        player.firstName.toLowerCase().includes(filters.firstName.toLowerCase()) &&
        player.lastName.toLowerCase().includes(filters.lastName.toLowerCase()) &&
        (player.position?.toLowerCase().includes(filters.position.toLowerCase()) || filters.position === '') &&
        (filters.birthYear === '' || new Date(player.dateOfBirth).getFullYear().toString().includes(filters.birthYear)) &&
        (player.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          player.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          player.position?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          player.team?.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue === undefined || bValue === undefined) return 0;
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [players, filters, searchTerm, sortColumn, sortDirection]);

  if (isLoading && players.length === 0) {
    return (
      <Sheet sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  if (error) {
    return (
      <Sheet sx={{ p: 2 }}>
        <Typography color="danger">{error}</Typography>
      </Sheet>
    );
  }

  return (
    <Sheet sx={{ p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Players</Typography>

      <Sheet sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Input
          startDecorator={<SearchIcon />}
          placeholder="Search players..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button onClick={() => setIsAddModalOpen(true)}>Add Player</Button>
      </Sheet>

      <Table
        stickyHeader
        sx={{
          '& th, & td': { borderBottom: '1px solid', borderColor: 'divider' },
          '& th': {
            backgroundColor: 'background.surface',
            verticalAlign: 'middle',
            '& svg': { verticalAlign: 'middle' }
          },
        }}
      >
        <thead>
          <tr>
            <th>
              <Input
                size="sm"
                placeholder="First Name"
                value={filters.firstName}
                onChange={(e) => handleFilterChange('firstName', e.target.value)}
              />
            </th>
            <th>
              <Input
                size="sm"
                placeholder="Last Name"
                value={filters.lastName}
                onChange={(e) => handleFilterChange('lastName', e.target.value)}
              />
            </th>
            <th>
              <Select
                size="sm"
                placeholder="Position"
                value={filters.position}
                onChange={(_, value) => handleFilterChange('position', value || '')}
              >
                <Option value="">All</Option>
                {Array.from(new Set(players.filter(p => p.position).map(p => p.position))).map(pos => (
                  <Option key={pos} value={pos}>{pos}</Option>
                ))}
              </Select>
            </th>
            <th>
              <Input
                size="sm"
                placeholder="Birth Year"
                value={filters.birthYear}
                onChange={(e) => handleFilterChange('birthYear', e.target.value)}
              />
            </th>
            <th>Actions</th>
          </tr>
          <tr>
            <th onClick={() => handleSort('firstName')}>
              First Name
              {sortColumn === 'firstName' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
            </th>
            <th onClick={() => handleSort('lastName')}>
              Last Name
              {sortColumn === 'lastName' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
            </th>
            <th onClick={() => handleSort('position')}>
              Position
              {sortColumn === 'position' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
            </th>
            <th onClick={() => handleSort('dateOfBirth')}>
              Birth Year
              {sortColumn === 'dateOfBirth' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedPlayers.map(player => (
            <tr key={player.id}>
              <td>
                <Button
                  variant="plain"
                  color="primary"
                  onClick={() => handlePlayerClick(player.id)}
                  sx={{
                    textDecoration: 'none',
                    padding: 0,
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  {player.firstName}
                </Button>
              </td>
              <td>{player.lastName}</td>
              <td>{player.position || 'N/A'}</td>
              <td>{new Date(player.dateOfBirth).getFullYear()}</td>
              <td>
                <IconButton onClick={() => handlePlayerClick(player.id)}>
                  <VisibilityIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <AddPlayerModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddPlayer={handleAddPlayer}
      />

      <Drawer
        anchor="right"
        open={isPlayerDetailsOpen}
        onClose={handleClosePlayerDetails}
        size="lg"
        slotProps={{
          content: {
            sx: {
              width: 'calc(100% - 280px)', // Adjust 280px based on your sidebar width + desired gap
              '@media (max-width: 600px)': {
                width: '100%', // Full width on small screens
              },
            },
          },
        }}
      >
        {selectedPlayerId && (
          <Sheet sx={{ height: '100%', overflow: 'auto' }}>
            <PlayerPage
              playerId={selectedPlayerId}
              onClose={handleClosePlayerDetails}
            />
          </Sheet>
        )}
      </Drawer>
    </Sheet>
  );
};

export default Players;