import React, { useState, useEffect } from 'react';
import { Sheet, Typography, FormControl, FormLabel, ButtonGroup, Button, Box } from '@mui/joy';

interface SettingsProps {
  demoMode: boolean;
  setDemoMode: (mode: boolean) => void;
}

const Settings: React.FC<SettingsProps> = ({ demoMode, setDemoMode }) => {
  const [unit, setUnit] = useState<'metric' | 'imperial'>('metric');

  useEffect(() => {
    const savedUnit = localStorage.getItem('unit');
    if (savedUnit === 'metric' || savedUnit === 'imperial') {
      setUnit(savedUnit);
    }
  }, []);

  const handleUnitChange = (newUnit: 'metric' | 'imperial') => {
    setUnit(newUnit);
    localStorage.setItem('unit', newUnit);
  };

  const buttonGroupSx = {
    '& .MuiButton-root': {
      width: '100px',  // Set a fixed width for all buttons
    },
  };

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ fontSize: '2xl', fontWeight: 'bold', mb: 4 }}>Settings</Typography>
      
      <FormControl orientation="horizontal" sx={{ mb: 2, justifyContent: 'space-between' }}>
        <FormLabel sx={{ flexGrow: 1 }}>Demo Mode</FormLabel>
        <Box>
          <ButtonGroup disabled sx={buttonGroupSx}>
            <Button variant={demoMode ? 'solid' : 'outlined'} color={demoMode ? 'primary' : 'neutral'}>
              On
            </Button>
            <Button variant={!demoMode ? 'solid' : 'outlined'} color={!demoMode ? 'primary' : 'neutral'}>
              Off
            </Button>
          </ButtonGroup>
        </Box>
      </FormControl>

      <FormControl orientation="horizontal" sx={{ mb: 2, justifyContent: 'space-between' }}>
        <FormLabel sx={{ flexGrow: 1 }}>Units</FormLabel>
        <Box>
          <ButtonGroup sx={buttonGroupSx}>
            <Button 
              onClick={() => handleUnitChange('metric')}
              variant={unit === 'metric' ? 'solid' : 'outlined'}
              color={unit === 'metric' ? 'primary' : 'neutral'}
            >
              Metric
            </Button>
            <Button 
              onClick={() => handleUnitChange('imperial')}
              variant={unit === 'imperial' ? 'solid' : 'outlined'}
              color={unit === 'imperial' ? 'primary' : 'neutral'}
            >
              Imperial
            </Button>
          </ButtonGroup>
        </Box>
      </FormControl>
    </Sheet>
  );
};

export default Settings;