import axios from 'axios';
import { Combine, CombineSession, Player, TestDefinition, TestResult, User, Organization, Feedback, UserAgreementLog, Connection } from './types/interfaces';
import { fetchAuthSession } from 'aws-amplify/auth';

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const { tokens } = await fetchAuthSession();
      const token = tokens?.idToken?.toString();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Error fetching auth session:', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Combines
export const getAllCombines = () => api.get<Combine[]>('/combines');
export const getCombine = (id: string) => api.get<Combine>(`/combines/${id}`);
export const createCombine = (data: Partial<Combine>) => api.post<Combine>('/combines', data);
export const updateCombine = (id: string, data: Partial<Combine>) => api.put<Combine>(`/combines/${id}`, data);
export const deleteCombine = (id: string) => api.delete(`/combines/${id}`);

// Combine Sessions
export const getAllCombineSessions = (combineId?: string) =>
  api.get<CombineSession[]>(combineId ? `/combines/${combineId}/sessions` : '/combine-sessions');
export const getCombineSession = (id: string) => api.get<CombineSession>(`/combine-sessions/${id}`);
export const createCombineSession = (data: Partial<CombineSession>) => api.post<CombineSession>('/combine-sessions', data);
export const updateCombineSession = (id: string, data: Partial<CombineSession>) => api.put<CombineSession>(`/combine-sessions/${id}`, data);
export const deleteCombineSession = (id: string) => api.delete(`/combine-sessions/${id}`);

// Players
export const getAllPlayers = () => api.get<Player[]>('/players');
export const getPlayer = (id: string) => api.get<Player>(`/players/${id}`);
export const createPlayer = (data: Partial<Player>) => api.post<Player>('/players', data);
export const updatePlayer = (id: string, data: Partial<Player>) => api.put<Player>(`/players/${id}`, data);
export const deletePlayer = (id: string) => api.delete(`/players/${id}`);
export const getPlayersForCombine = (combineId: string) => api.get<Player[]>(`/combines/${combineId}/players`);
export const createPlayerForCombine = (combineId: string, data: Partial<Player>) =>
  api.post<Player>(`/combines/${combineId}/players`, data);

// Test Definitions
export const getAllTestDefinitions = () => api.get<TestDefinition[]>('/test-definitions');
export const getTestDefinition = (id: string) => api.get<TestDefinition>(`/test-definitions/${id}`);
export const createTestDefinition = (data: Partial<TestDefinition>) => api.post<TestDefinition>('/test-definitions', data);
export const updateTestDefinition = (id: string, data: Partial<TestDefinition>) => api.put<TestDefinition>(`/test-definitions/${id}`, data);
export const deleteTestDefinition = (id: string) => api.delete(`/test-definitions/${id}`);
export const getTestsForCombine = (combineId: string) => api.get<TestDefinition[]>(`/combines/${combineId}/tests`);

// Test Results
export const getAllTestResults = (combineId?: string, sessionId?: string) =>
  api.get<TestResult[]>(
    combineId ? `/combines/${combineId}/test-results` :
      sessionId ? `/combine-sessions/${sessionId}/test-results` :
        '/test-results'
  );
export const getTestResult = (id: string) => api.get<TestResult>(`/test-results/${id}`);
export const createTestResult = (data: Partial<TestResult>) => api.post<TestResult>('/test-results', data);
export const updateTestResult = (id: string, data: Partial<TestResult>) => api.put<TestResult>(`/test-results/${id}`, data);
export const deleteTestResult = (id: string) => api.delete(`/test-results/${id}`);
// In api.ts
export const bulkAddTestResults = (combineId: string, data: Partial<TestResult>[]) =>
  api.post<{ ids: string[] }>(`/combines/${combineId}/bulk-add-test-results`, { results: data });

export const addTestResults = (combineId: string, results: Partial<TestResult>[]) =>
  api.post<{ message: string }>(`/combines/${combineId}/test-results`, { results });

// Check for existing players
export const checkExistingPlayers = (players: Partial<Player>[]) =>
  api.post<{ input: Player; match: Player | null; action: 'match' | 'create' }[]>('/players/check-existing', { players });

// Bulk create players
export const bulkCreatePlayers = (players: Partial<Player>[]) =>
  api.post<Player[]>('/players/bulk-create', { players });

// Add players to combine
export const addPlayersToCombine = (combineId: string, data: { players: string[] }) =>
  api.post<{ message: string, players: Player[] }>(
    `/combines/${combineId}/players`,
    data
  );

export const fetchPlayersByIds = (data: { players: string[] }) =>
  api.post<Player[]>('/players/bulk-fetch', data);


//Users
export const getCurrentUser = () => api.get<User>('/users/me');

export const getTestResultsByPlayer = (playerId: string) =>
  api.get<TestResult[]>(`/test-results/player/${playerId}`);

export const getCombinesByPlayer = (playerId: string) =>
  api.get<Combine[]>(`/players/${playerId}/combines`)
    .then(response => {
      console.log('API response:', response.data);
      return response.data;
    });

export const logUserAgreement = async (agreementData: UserAgreementLog) => {
  const response = await api.post<{ message: string }>('/user-agreements', agreementData);
  return response.data;
};

export const updateUser = async (userId: string, userData: Partial<User>) => {
  const response = await api.patch<User>(`/users/${userId}`, userData);
  return response.data;
};

//Organizations
export const getAllOrganizations = () => api.get<Organization[]>('/organizations');
export const getOrganization = (id: string) => api.get<Organization>(`/organizations/${id}`);
export const createOrganization = (data: Partial<Organization>) => api.post<Organization>('/organizations', data);
export const updateOrganization = (id: string, data: Partial<Organization>) => api.put<Organization>(`/organizations/${id}`, data);

//Feedback
export const submitFeedback = (data: Partial<Feedback>) => api.post<Feedback>('/feedback', data);

//Connections
export const getCurrentUserConnections = () => api.get<Connection[]>('/users/me/connections');


export default api;