// src/pages/ImportCombine.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Sheet, CircularProgress } from '@mui/joy';
import ImportCombineModal from '../components/ImportCombineModal';
import { Player, TestDefinition, TestResult } from '../types/interfaces';
import { getAllTestDefinitions, getAllPlayers } from '../api';

const ImportCombine: React.FC = () => {
  const navigate = useNavigate();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [existingPlayers, setExistingPlayers] = useState<Player[]>([]);
  const [testDefinitions, setTestDefinitions] = useState<TestDefinition[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [playersResponse, testDefinitionsResponse] = await Promise.all([
        getAllPlayers(),
        getAllTestDefinitions()
      ]);
      setExistingPlayers(playersResponse.data);
      setTestDefinitions(testDefinitionsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load necessary data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenImport = () => {
    if (existingPlayers.length > 0 && testDefinitions.length > 0) {
      setIsImportModalOpen(true);
    } else {
      setError('Data not loaded. Please refresh the page and try again.');
    }
  };

  const handleImportComplete = (players: Player[], testResults: TestResult[]) => {
    // Here you would typically make API calls to create the combine, 
    // associate players, and add test results.
    console.log('Import completed:', { players, testResults });
    // For now, we'll just navigate back to the combines list
    navigate('/combines');
  };

  if (isLoading) {
    return (
      <Sheet variant="outlined" sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Sheet>
    );
  }

  return (
    <Sheet variant="outlined" sx={{ p: 4 }}>
      <Typography level="h1" sx={{ mb: 4 }}>Import Combine</Typography>
      {error && (
        <Typography color="danger" sx={{ mb: 2 }}>{error}</Typography>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 2 }}>
          Import a new combine by uploading a CSV file with player information and test results.
        </Typography>
        <Button onClick={handleOpenImport} disabled={isLoading || !!error}>Start Import</Button>
      </Box>
      <ImportCombineModal
        open={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onImportComplete={handleImportComplete}
        existingPlayers={existingPlayers}
        testDefinitions={testDefinitions}
      />
    </Sheet>
  );
};

export default ImportCombine;