// src/contexts/CombineDetailsContext.tsx

import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { Combine, Player, TestDefinition, TestResult } from '../types/interfaces';
import { getCombine, updateCombine, getPlayersForCombine, getAllTestDefinitions, getAllTestResults } from '../api';

interface CombineDetailsContextType {
  combine: Combine | null;
  players: Player[];
  allTests: TestDefinition[];
  selectedTests: TestDefinition[];
  testResults: TestResult[];
  isLoading: boolean;
  error: string | null;
  usesSessions: boolean;
  loadCombineData: (combineId: string) => Promise<void>;
  updateTestSchedule: (updatedTestIds: string[]) => Promise<void>;
  updateRoster: (updatedPlayers: Player[]) => void;
  updateTestResults: (newResults: TestResult[]) => void;
  isEditingAllowed: (status: string) => boolean;
}

const CombineDetailsContext = createContext<CombineDetailsContextType | undefined>(undefined);

export const useCombineDetails = () => {
  const context = useContext(CombineDetailsContext);
  if (context === undefined) {
    throw new Error('useCombineDetails must be used within a CombineDetailsProvider');
  }
  return context;
};

export const CombineDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [combine, setCombine] = useState<Combine | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [allTests, setAllTests] = useState<TestDefinition[]>([]);
  const [testResults, setTestResults] = useState<TestResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [usesSessions, setUsesSessions] = useState(false);

  const loadCombineData = useCallback(async (combineId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const [combineData, playersData, testsData, resultsData] = await Promise.all([
        getCombine(combineId),
        getPlayersForCombine(combineId),
        getAllTestDefinitions(),
        getAllTestResults(combineId)
      ]);
      setCombine(combineData.data);
      setPlayers(playersData.data);
      setAllTests(testsData.data);
      setTestResults(resultsData.data);
      setUsesSessions(combineData.data.usesSessions ?? false);
    } catch (err) {
      setError('Failed to load combine data. Please try again.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateTestSchedule = async (updatedTestIds: string[]) => {
    if (!combine) {
      console.error('Cannot update test schedule: combine is null');
      return;
    }
  
    console.log('Updating test schedule with IDs:', updatedTestIds);
  
    // Immediately update local state
    setCombine(prevCombine => {
      if (!prevCombine) return null;
      return { ...prevCombine, testIds: updatedTestIds };
    });
  
    // Sync with backend without showing loading indicator
    try {
      const updatedCombine = await updateCombine(combine.id, { testIds: updatedTestIds });
      console.log('Updated combine received:', updatedCombine.data);
      
      // Update combine with any additional data from the backend
      setCombine(prevCombine => {
        if (!prevCombine) return null;
        return { 
          ...prevCombine, 
          ...(typeof updatedCombine.data === 'object' ? updatedCombine.data : {}),
          testIds: updatedTestIds // Ensure testIds are always as we expect
        };
      });
  
      // Fetch updated test results in the background
      getAllTestResults(combine.id).then(resultsData => {
        console.log('Updated test results received:', resultsData.data.length);
        setTestResults(resultsData.data);
      });
  
    } catch (err) {
      console.error('Error syncing test schedule with backend:', err);
      // Optionally, revert the local change if the backend update fails
      // setCombine(prevCombine => ({ ...prevCombine, testIds: prevCombine.testIds }));
      setError('Failed to sync tests with server. Please try again.');
    }
  
    console.log('Finished updating test schedule');
  };

  const updateRoster = (updatedPlayers: Player[]) => {
    setPlayers(updatedPlayers);
  };

  const updateTestResults = (newResults: TestResult[]) => {
    setTestResults(prevResults => {
      const updatedResults = [...prevResults];
      newResults.forEach(newResult => {
        const index = updatedResults.findIndex(r => r.id === newResult.id);
        if (index !== -1) {
          updatedResults[index] = newResult;
        } else {
          updatedResults.push(newResult);
        }
      });
      return updatedResults;
    });
  };

  const isEditingAllowed = useCallback((status: string) => {
    return status !== 'completed';
  }, []);

  const selectedTests = useMemo(() => {
    if (!combine || !combine.testIds) return [];
    return allTests.filter(test => combine.testIds!.includes(test.id));
  }, [combine, allTests]);

  const value = {
    combine,
    players,
    allTests,
    selectedTests,
    testResults,
    isLoading,
    error,
    usesSessions,
    loadCombineData,
    updateTestSchedule,
    updateRoster,
    updateTestResults,
    isEditingAllowed,
  };

  return (
    <CombineDetailsContext.Provider value={value}>
      {children}
    </CombineDetailsContext.Provider>
  );
};